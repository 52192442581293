<template>
  <overall-settings v-bind="$props">
    <personal-center :fields="fields?.personalCenterBar?.fields" active-menu="my-order">
      <div class="c-order-detail" v-if="fields">
        <div class="c-order-detail__header">
          <div class="c-order-detail__header-side">
            <div
              class="c-order-detail__title"
              v-html="
                $formatString(fields.title?.value, {
                  model: orderDetail?.vehicleInfo?.basicInfo?.model ?? '',
                  financeType: $tu(orderDetail?.vehicleInfo?.basicInfo?.paymentInfo?.paymentSchemeCode === '200' ? 'Loan' : 'Cash')
                })
              "
            />
            <div class="c-order-detail__status">{{ orderStatus?.name }}</div>
          </div>
          <div class="c-order-detail__help">
            <icon :field="fields?.helpIcon" />
            <jss-rich-text class="c-order-detail__help-text" :field="fields?.helpText" tag="div" />
          </div>
        </div>
        <div class="c-order-detail__wrapper">
          <div class="c-order-detail__side">
            <div class="c-order-detail__order-nos">
              <div class="c-order-detail__order-no">
                <p>{{ $t('Order number') }}</p>
                <span> {{ orderDetail?.salesOrderInfo?.orderId }} </span>
              </div>
              <div class="c-order-detail__order-no" v-if="orderDetail?.reservationInfo">
                <p>{{ $t('Ralated reservation number') }}</p>
                <span> {{ orderDetail?.reservationInfo?.reservationNo }} </span>
              </div>
            </div>
            <div class="c-order-detail__steps">
              <div class="c-order-detail__step" :class="[{ current: isCurrent(step, index), watching, active: isActive(step, index) }]" v-for="(step, index) in fields.steps" :key="step.id">
                <div class="c-order-detail__step-side">
                  <div class="c-order-detail__step-icon">
                    <icon :field="step.fields.icon" />
                  </div>
                  <span class="c-order-detail__step-line"></span>
                </div>
                <div class="c-order-detail__step-content">
                  <template v-if="isActive(step, index)">
                    <div class="c-order-detail__step-title" v-html="getStateTitle(step, index)" />
                    <div class="c-order-detail__step-description" v-html="getStateDescription(step, index)" />
                    <button class="c-order-detail__step-button" v-html="getStateButtonText(step, index)" @click="onProcessOrder($event, step, index)" />
                  </template>
                  <div class="c-order-detail__step-disabled" v-else>
                    <jss-rich-text class="c-order-detail__step-disabled-title" :field="step.fields?.disabledTitle" />
                    <jss-rich-text class="c-order-detail__step-disabled-body" :field="step.fields?.disabledBody" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="bottom-slide-in">
            <div class="c-order-detail__main" v-if="!['mobile', 'tablet'].includes(deviceState.deviceType) || detailHasOpened">
              <img class="c-order-detail__model-image" :src="orderDetail?.vehicleInfo?.basicInfo?.image" alt="Model image" v-if="orderDetail?.vehicleInfo?.basicInfo?.image" />
              <div class="c-build-to-order__delivery-date" v-html="$formatString(fields.expectedDeliveryDate?.value, { date: carEngine.expectedDeliveryDate })" v-if="carEngine" />
              <div class="c-order-detail__main-content">
                <div class="c-order-detail__model-title">{{ orderDetail?.vehicleInfo?.basicInfo?.model }}</div>
                <label-value class="c-order-detail__delivery-amount" :label="$t('Total deposit')" :value="$formatMoney(orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency)" />
                <div class="c-order-detail__config-sections">
                  <div class="c-order-detail__config-section">
                    <div class="c-order-detail__config-section-title" @click="vehicleDetailHasOpened = !vehicleDetailHasOpened">
                      <span>{{ $t('Vehicle details') }}</span>
                      <accordion-toggle :active="vehicleDetailHasOpened" />
                    </div>
                    <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                      <div class="c-order-detail__config-section-content" v-if="vehicleDetailHasOpened">
                        <div class="c-build-to-order__config-section-sub" v-for="(feature, index) in vehicleDetail" :key="index">
                          <!--                  <jss-image class="c-build-to-order__section-image" :media="fields.exteriorImage" />-->
                          <template v-if="feature.infos?.length > 0">
                            <div class="c-build-to-order__config-section-subtitle">{{ feature.type }}</div>
                            <div class="c-build-to-order__vehicle-detail-content">
                              <template v-for="(info, j) in feature.infos" :key="j">
                                <label-value
                                  class="c-build-to-order__vehicle-detail-item"
                                  :label="info.value"
                                  :value="info.price >= 0 ? $formatMoney(info.price, info.currency) : $t('Included').toLowerCase()"
                                />
                                <div class="c-build-to-order__vehicle-detail-value" v-html="info.label" />
                              </template>
                            </div>
                          </template>
                        </div>
                      </div>
                    </transition>
                  </div>
                  <div class="c-order-detail__config-section">
                    <div class="c-order-detail__config-section-title" @click="quotationHasOpened = !quotationHasOpened">
                      <span>{{ $t('My quotation') }}</span>
                      <accordion-toggle :active="quotationHasOpened" />
                    </div>
                    <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                      <div class="c-order-detail__config-section-content" v-if="quotationHasOpened">
                        <label-value :label="$t('On the road price')" :value="$formatMoney(totalAmount, orderDetail?.currency)" />
                        <div class="c-order-detail__config-section-block">
                          <label-value :label="priceItem.label" :value="$formatMoney(priceItem.price, priceItem.currency)" v-for="priceItem in priceInfo" :key="priceItem.elementName" />
                          <div class="c-order-detail__config-section-description">Incl. registration cost + delivery cost + recycling fee</div>
                        </div>
                        <template v-if="benefitsList?.length > 0">
                          <label-value :label="`${$t('Offer')}/${$t('Service')}`" :value="$formatMoney(benefitsAmount, orderDetail?.currency)" />
                          <div class="c-build-to-order__config-section-block">
                            <label-value
                              :label="benefits.name"
                              :value="Number(benefits.price) > 0 ? $formatMoney(benefits.price, currency) : $t('Included').toLowerCase()"
                              v-for="benefits in benefitsList"
                              :key="benefits.rightsId"
                            />
                          </div>
                        </template>
                        <label-value :label="$t('Total amount payable')" :value="$formatMoney(totalAmountPayable, orderDetail?.currency)" />
                        <div class="c-order-detail__config-section-block">
                          <label-value :label="$t('On the road price')" :value="$formatMoney(totalAmount, orderDetail?.currency)" />
                          <label-value :label="$t('Total deposit')" :value="`- ${$formatMoney(orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency)}`" />
                          <label-value :label="$t('Financed amount')" :value="Number(orderDetail?.financeAmount) > 0 ? `-${$formatMoney(orderDetail?.financeAmount, orderDetail?.currency)}` : '--'" />
                          <label-value theme="yellow" :label="$t('Customer balance')" :value="$formatMoney(orderDetail?.paymentInfo?.balancePaymentAmount, currency)" v-if="totalAmountPayable === 0" />
                        </div>
                        <label-value :label="$t('Deposit fee')" :value="$formatMoney(orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency)" />
                      </div>
                    </transition>
                  </div>
                  <div class="c-order-detail__config-section">
                    <div class="c-order-detail__config-section-title" @click="personalDetailHasOpened = !personalDetailHasOpened">
                      <span>{{ $t('Personal details') }}</span>
                      <accordion-toggle :active="personalDetailHasOpened" />
                    </div>
                    <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                      <div class="c-order-detail__config-section-content" v-if="personalDetailHasOpened">
                        <div class="c-order-detail__personal-info">
                          {{ $t('Name') }} <br />
                          {{ $concatString([orderDetail?.userInfo?.firstName, orderDetail?.userInfo?.middleName, orderDetail?.userInfo?.lastName]) }}
                        </div>
                        <div class="c-order-detail__personal-info">
                          {{ $t('Phone number') }} <br />
                          +{{ orderDetail?.userInfo?.areaCode }}{{ orderDetail?.userInfo?.phoneNumber }}
                        </div>
                        <div class="c-order-detail__personal-info">
                          {{ $t('Email address') }} <br />
                          {{ orderDetail?.userInfo?.email }}
                        </div>
                        <div class="c-order-detail__personal-info">
                          {{ $t('Shipping address') }} <br />
                          {{
                            $buildAddress(
                              orderDetail?.vehicleInfo?.deliveryInfo?.street,
                              orderDetail?.vehicleInfo?.deliveryInfo?.zipCode,
                              orderDetail?.vehicleInfo?.deliveryInfo?.city,
                              orderDetail?.vehicleInfo?.deliveryInfo?.countryName
                            )
                          }}
                        </div>
                        <div class="c-order-detail__personal-info">
                          {{ $t('Billing address') }} <br />
                          {{
                            $buildAddress(
                              orderDetail?.salesOrderInfo?.taxpayerStreet,
                              orderDetail?.salesOrderInfo?.taxpayerZipCode,
                              orderDetail?.salesOrderInfo?.taxpayerCity,
                              orderDetail?.salesOrderInfo?.countryName
                            )
                          }}
                        </div>
                        <div class="c-build-to-order-detail__personal-info">
                          {{ $t('Preferred agent') }} <br />
                          {{ orderDetail?.userInfo?.store }}
                        </div>
                        <template v-if="orderDetail?.salesOrderInfo?.companyName">
                          <div class="c-build-to-order-detail__personal-info">
                            {{ $t('VAT number') }} <br />
                            {{ orderDetail?.salesOrderInfo?.vatNumber }}
                          </div>
                          <div class="c-build-to-order-detail__personal-info">
                            {{ $t('Company name') }} <br />
                            {{ orderDetail?.salesOrderInfo?.companyName }}
                          </div>
                          <div class="c-build-to-order-detail__personal-info">
                            {{ $t('Company registration number') }} <br />
                            {{ orderDetail?.salesOrderInfo?.companyRegistrationNumber }}
                          </div>
                        </template>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="c-order-detail__toolbar" ref="toolbarEl">
          <div class="c-order-detail__detail-toggle">
            <span>{{ $t('Order details') }}</span>
            <accordion-toggle :active="detailHasOpened" @click="toggleDetailHasOpened" />
          </div>
        </div>
      </div>
    </personal-center>
  </overall-settings>
  <modal class="c-order-detail__pay-modal" ref="modalPayRef" closable @closed="onPayModalClosed">
    <jss-rich-text class="c-order-detail__pay-modal-title" :field="fields?.stripeTitle" tag="div" />
    <div ref="payWidgetRef" />
    <div class="c-order-detail__pay-modal-buttons">
      <site-button :fields="fields?.payButton?.fields" @click="onPay" />
    </div>
  </modal>
  <modal class="c-order-detail__bank-modal" ref="modalBankRef" closable>
    <div class="c-order-detail__bank-modal-price">{{ $formatMoney(totalAmount - orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency) }}</div>
    <jss-rich-text class="c-order-detail__bank-modal-title" :field="fields.bankTitle" />
    <jss-rich-text class="c-order-detail__bank-modal-desc" :field="fields.bankDescription" />
    <div class="c-order-detail__bank-modal-info">
      <div class="c-order-detail__bank-modal-item">
        <div class="c-order-detail__bank-modal-item__label">{{ $t('Order number') }}</div>
        <div class="c-order-detail__bank-modal-item__value">{{ orderDetail?.salesOrderInfo?.orderId }}</div>
      </div>
      <div class="c-order-detail__bank-modal-item" v-for="(key, index) of Object.keys(bankInfo)" :key="index">
        <div class="c-order-detail__bank-modal-item__label">{{ key.replace(/_/gi, ' ') }}</div>
        <div class="c-order-detail__bank-modal-item__value">{{ bankInfo[key] }}</div>
      </div>
    </div>
    <jss-rich-text class="c-order-detail__bank-modal-body" :field="fields.bankBody" />
  </modal>
  <notice-modal v-bind="fields.localeRedirectModal" ref="orderLocaleRedirectModalRef" />
</template>

<script>
/**
 * @typedef OrderDetailFields
 * @property {ImageField} helpIcon
 * @property {SimpleField} helpText
 * @property {SimpleField} stripeTitle
 * */
import { onMounted, inject, toRefs, reactive, nextTick, computed } from 'vue';
import api from '@/api';
import { useRoute } from 'vue-router';
import useDevice from '@/hooks/useDevice';
import { canUseDOM, openWindow } from '@/utils/dom-utils';
import { loadStripe } from '@/utils/stripe-utils';
import { transitions } from '@/utils/transitions';
import { analyzeFeatures, buildModelImgUrl } from '@/utils/mhp-utils';
import useAppStore from '@/store/appStore';
import { getAlpha2CodeByLang, getCurrentAlpha2Code, getGlobalConfigs, getPageAlpha2Code, settingValue } from '@/utils/site-utils';
import { getQueryStrings, redirectToLang } from '@/utils/uri-utils';
import { equalString, formatString } from '@/utils/string-utils';
import services from '@/services';
import * as querystring from 'querystring';
import { ORDER_STATUES } from '@/utils/constants';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { setDictionaryValue, updateDictionaryValue } from '@/services/dictService';
import { sum } from '@/utils/math-utils';
import { getCarModel } from '@/services/siteService';
import { qtUtils } from '@/utils/ali-tracker-utils';

export default {
  name: 'OrderDetail',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    /**@type AppStore*/
    const appStore = useAppStore();
    const route = useRoute();
    const { deviceState } = inject('device-common');
    const toast = inject('toast');
    const loading = inject('loading');
    const { orderId } = route?.query || {};
    let confirmPayment = null;
    const _methods = {
      async setDetail(res) {
        // await _methods.loadConfiguration(res);
        state.orderDetail = res;
        state.currency = res?.currency;
        const priceInfo = res?.additionalTaxInfo;
        state.carEngine = await getCarModel(res.vehicleInfo?.basicInfo?.series, res.vehicleInfo?.basicInfo?.filter);
        state.priceInfo = await updateDictionaryValue(priceInfo, 'elementName', 'label', (x) => x.visibility);
        state.vehicleDetail = JSON.parse(res?.vehicleInfo?.basicInfo?.infoJson ?? '[]');
        state.invoiceInfo = res?.invoiceInfoList?.length > 0 ? res?.invoiceInfoList[0] : null;
        state.orderStatus = await services.order.getOrderStatus(res?.salesOrderInfo?.orderStatus);
        state.totalAmount =
          state.priceInfo.find((x) => x.elementName === 'Total Price')?.price ??
          Math.max.apply(
            null,
            state.priceInfo.filter((x) => !isNaN(x.price)).map((x) => Number(x.price))
          ) ??
          null;
        state.totalAmountPayable = state.totalAmount - (res?.salesOrderInfo?.totalAmountNumber ?? 0) - (res?.financeAmount ?? 0);
        state.benefitsList = res?.rightsRespVOS?.sort((a, b) => b.price - a.price) ?? [];
        state.benefitsAmount = sum(res?.rightsRespVOS ?? [], 'price');
        qtUtils.trackPv('my_order_pg', {
          order_number: res?.salesOrderInfo?.orderId,
          model_selection: res?.vehicleInfo?.basicInfo?.model,
          car_package_info: res?.vehicleInfo?.basicInfo?.infoJson,
          order_stage: state.orderStatus?.name
        });
        loading.hide();
      },
      async watching() {
        state.watching = true;
        const [res, ex] = await api.order.intactDetail(null, {
          id: orderId
        });
        if (ex) {
          await toast.showEx(ex);
          return;
        }
        await _methods.setDetail(res);
        if (res?.vehicleOrderSignVO?.isSign !== 20) {
          setTimeout(_methods.watching, 1000);
          return;
        }
        state.watching = false;
      },
      async loadConfiguration(orderDetail) {
        const [formulaNo] = getGlobalConfigs(props.page, 'formulaNo');
        const { filterId, transferCode } = orderDetail?.vehicleInfo?.basicInfo ?? {};
        const [res, ex] = await api.configuration.get(null, {
          filterId,
          transferCode,
          engineeringCode: 1,
          showPrice: 1
        });
        if (ex) {
          await toast.showEx(ex);
          return;
        }
        state.configuration = res;
        const [, features, priceIds, featureCodes, packageList, buttonFeatureTypes] = analyzeFeatures(res);
        state.features = features;
        state.modelImg = buildModelImgUrl(props.page, filterId, res.filterInteractionName, buttonFeatureTypes);
      }
    };
    const methods = {
      getStateField(step, index, field) {
        const { scenes } = step.fields || {};
        let result = step.fields[field]?.value;
        if (index === 0 && state.orderDetail?.vehicleOrderSignVO?.isSign === 20) {
          const scene = scenes.find((x) => settingValue(x.fields.type) === 'completed');
          if (!isNullOrEmpty(scene?.fields[field]?.value)) {
            result = scene?.fields[field]?.value;
          }
        }
        if (index === 1 && equalString(state.orderStatus.code, '30')) {
          const scene = scenes.find((x) => settingValue(x.fields.type) === 'completed');
          if (!isNullOrEmpty(scene?.fields[field]?.value)) {
            result = scene?.fields[field]?.value;
          }
        }
        return result;
      },
      getStateTitle(step, index) {
        let result = methods.getStateField(step, index, 'title');
        if (isNullOrEmpty(result)) {
          result = props.fields.stepTitle?.value;
        }
        return formatString(result, { step: index > 0 ? index.toString() : ' ' });
      },
      getStateDescription(step, index) {
        return methods.getStateField(step, index, 'description');
      },
      getStateButtonText(step, index) {
        return methods.getStateField(step, index, 'buttonText');
      },
      async onProcessOrder(e, step, index) {
        if (state.watching) return;
        const { orderStatus } = state.orderDetail?.salesOrderInfo ?? {};
        switch (index) {
          case 0: {
            const { isSign, contentList } = state.orderDetail?.vehicleOrderSignVO ?? {};
            if (isSign === 20 && contentList?.length > 0) {
              openWindow(contentList[0], '_blank');
              return;
            } else if ([ORDER_STATUES.Deposit, ORDER_STATUES.DepositLock].includes(orderStatus) && state.orderDetail?.salesOrderInfo?.isCanSignContract) {
              const [res, ex] = await api.order.createContract(null, {
                orderId: orderId,
                oneId: state?.orderDetail?.userInfo?.oneId,
                redirectUrl: window.location.href
              });
              if (ex) {
                await toast.showEx(ex);
                return;
              }
              // const [resUrl, exUrl] = await api.order.signShortUrl(null, {
              //   flowId: res.flowId,
              //   redirectUrl: res.redirectUrl
              // });
              // if (exUrl) {
              //   await toast.showEx(exUrl);
              //   return;
              // }
              window.location = res.shortUrl;
              // state.orderDetail.salesOrderInfo.orderStatus = 'Contract Signed';
              // console.log('changed');
            }
            break;
          }
          case 1:
            {
              if (orderStatus === ORDER_STATUES.ContractSigned) {
                const [nonEurozone] = getGlobalConfigs(props.page, 'nonEurozone');
                const alpha2Code = getPageAlpha2Code(props.page);
                if (nonEurozone.value.split(';').includes(alpha2Code)) {
                  state.modalBankRef.open();
                  return;
                }
                loading.show();
                const [res, ex] = await api.order.intactPay(null, {
                  orderId,
                  oneId: state?.orderDetail?.userInfo?.oneId,
                  type: 2
                });
                if (ex) {
                  await toast.showEx(ex);
                  return;
                }
                await state.modalPayRef.open();
                const stripeHelper = await loadStripe(props.page, toast);
                if (!stripeHelper) {
                  loading.hide();
                  return;
                }
                const stripe = stripeHelper.initSdk(res);
                confirmPayment = stripeHelper.initWidget(stripe, state.payWidgetRef, res);
                loading.hide();
              }
            }
            break;
          default:
            break;
        }
      },
      async onPay() {
        loading.show();
        await confirmPayment(window.location.href);
        loading.hide();
      },
      onPayModalClosed() {
        loading.hide();
      },
      toggleDetailHasOpened() {
        state.detailHasOpened = !state.detailHasOpened;
      },
      getAmount(name) {
        return state.orderDetail?.additionalTaxInfo?.find((x) => x.elementName === name)?.price ?? 0;
      },
      isCurrent(step, index) {
        if (index === 0 && [ORDER_STATUES.Deposit, ORDER_STATUES.DepositLock].includes(state.orderDetail?.salesOrderInfo?.orderStatus)) {
          return state.orderDetail?.salesOrderInfo?.isCanSignContract ?? false;
        }
        return step.fields.orderStatuses.some((x) => state.orderDetail?.salesOrderInfo?.orderStatus === x.fields.code.value);
      },
      isActive(step, index) {
        return methods.isCurrent(step, index) || (index === 0 && state.orderDetail?.vehicleOrderSignVO?.isSign === 20);
      }
    };
    const state = reactive({
      orderId,
      vehicleDetailHasOpened: false,
      quotationHasOpened: false,
      personalDetailHasOpened: false,
      detailHasOpened: false,
      orderDetail: null,
      carEngine: null,
      priceInfo: [],
      vehicleDetail: [],
      invoiceInfo: null,
      modelImg: null,
      /**@type ModalRef*/
      modalPayRef: null,
      payWidgetRef: null,
      /**@type ModalRef*/
      modalBankRef: null,
      currency: null,
      features: [],
      totalAmount: null,
      totalAmountPayable: null,
      orderStatus: null,
      watching: false,
      /**@type NoticeModalRef*/
      orderLocaleRedirectModalRef: null,
      benefitsList: [],
      benefitsAmount: 0
    });
    const computes = {
      bankInfo: computed(() => querystring.parse(props.fields.bankInfo.value))
    };
    if (canUseDOM()) {
      loading.show();
    }
    onMounted(async () => {
      loading.show();
      const [event] = getQueryStrings('event');
      const [res, ex] = await api.order.intactDetail(null, {
        id: orderId
      });
      if (ex) {
        await toast.showEx(ex);
        return;
      }
      const [needRedirect, targetLang] = services.order.checkOrderLang(props.page, res?.salesOrderInfo?.countryCode);
      if (needRedirect) {
        await state.orderLocaleRedirectModalRef.open();
        redirectToLang(targetLang);
      }
      if (equalString(event, 'signing_complete') && res?.vehicleOrderSignVO?.isSign !== 20) {
        await _methods.watching();
        return;
      }
      await _methods.setDetail(res);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      deviceState,
      transitions
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-order-detail {
  $c: &;
  &__header {
    padding: 16px;
    margin-bottom: 24px;
  }
  &__header-side {
    display: flex;
    align-items: flex-start;
  }
  &__title {
    @include h7;
    margin-bottom: 16px;
  }
  &__status {
    margin-left: 16px;
    padding: 6px 16px;
    margin-top: -5px;
    background: $khaki;
  }
  &__side {
    min-height: calc(100vh - #{nth($header-height, 1)});
    padding-left: 16px;
    padding-top: 10px;
  }
  &__order-nos {
    display: flex;
    justify-content: space-between;
  }
  &__order-no {
    margin-bottom: 24px;
    > p {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__step {
    display: flex;
    align-items: stretch;
    &-side {
      margin-right: 16px;
      width: 40px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .e-icon {
        border-radius: 50%;
        background: $grey;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 40px;
          height: auto;
        }
      }
    }
    &-line {
      flex-grow: 1;
      border-left: 1px dashed $grey;
    }
    &-content {
      flex-grow: 1;
      padding-bottom: 80px;
    }
    &-button {
      @include h10;
      border: 1px solid $white;
      background: transparent;
      color: $white;
      width: 170px;
      text-align: center;
      padding: 12px;
      margin-top: 16px;
    }
    &-disabled {
      opacity: 0.6;
      &-title {
        margin-bottom: 12px;
      }
      &-body {
        font-family: lotusFontFamily('Overpass Light'), sans-serif;
      }
    }
    &.active:not(.watching) {
      #{$c}__step {
        &-button {
          cursor: pointer;
        }
      }
    }
    &.current:not(.watching) {
      #{$c}__step {
        &-side {
          .e-icon {
            background: $yellow;
            color: $black;
            position: relative;
          }
        }
        &-icon {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: rgba($yellow, 0.2);
            animation: step-icon-ani 4000ms cubic-bezier(0.355, 0.005, 0.26, 1) infinite;
          }
        }
        &-title {
          color: $yellow;
        }
        &-description {
          font-family: lotusFontFamily('Overpass Light'), sans-serif;
        }
        &-button {
          background: $yellow;
          color: $black;
          border-color: yellow;
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
    &:last-child {
      #{$c}__step-content {
        padding-bottom: 0;
      }
    }
  }
  &__main {
    position: fixed;
    top: nth($header-height, 1);
    z-index: 2;
    width: 100vw;
    @include height-except-header;
    overflow-y: auto;
    background: $black;
    color: $white;
    @include h10;
    &-content {
      padding: 0 grid-width-m(1) 40px grid-width-m(1);
    }
  }
  &__detail-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $grey-neutral;
    color: $yellow;
    padding: $space-16;
    .e-accordion-toggle {
      background: $yellow;
      color: $black;
      width: 18px;
      height: 18px;
      &__content {
        width: 18px;
        height: 18px;
        padding: 4px;
        &:before {
          width: calc(100% - 8px);
        }
        &:after {
          height: calc(100% - 8px);
        }
      }
    }
  }
  &__deposit-bar {
    display: none;
  }
  &__model-image {
    display: block;
    width: 100%;
    height: auto;
    //mask-image: radial-gradient(ellipse 95% 90% at 50% 50%, black 40%, transparent 50%);
  }
  &__delivery-date {
    background: $yellow;
    text-align: center;
    padding: 8px;
    color: $black;
  }
  &__model-title {
    padding: 24px 0 32px 0;
    font-size: 16px;
  }
  &__section-image {
    width: 120px;
    height: auto;
    display: block;
    margin-bottom: 8px;
  }
  &__config-section {
    &-title {
      display: flex;
      justify-content: space-between;
      @include h9;
      padding: 16px 0;
    }
    &-subtitle {
      margin-bottom: 12px;
    }
    &-description {
      font-family: lotusFontFamily('Overpass Thin'), sans-serif;
      text-align: justify;
      margin-right: 8px;
    }
    &-content {
      > .e-label-value {
        margin: 10px 8px 10px 0;
      }
    }
    &-subitem {
      font-family: lotusFontFamily('Overpass Thin'), sans-serif;
    }
    &-sub {
      & + & {
        margin-top: 16px;
      }
      .e-label-value,
      #{$c}__config-section-description {
        + .e-label-value,
        + #{$c}__config-section-description {
          margin-top: 8px;
        }
      }
    }
    &-block {
      background: rgba($grey-neutral, 0.5);
      padding: 16px 8px;
      .e-label-value,
      #{$c}__config-section-description {
        + .e-label-value,
        + #{$c}__config-section-description {
          margin-top: 16px;
        }
      }
      > .e-label-value {
        margin-right: 60px;
      }
    }
    + #{$c}__config-section {
      border-top: 1px solid rgba($white, 0.5);
      margin-top: 24px;
    }
  }
  &__personal-info {
    margin-bottom: 16px;
  }
  &__toolbar {
    position: fixed;
    top: 100vh;
    transform: translateY(-100%);
    z-index: 2;
    width: 100%;
    background: $white;
  }
  &__detail-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $grey-neutral;
    color: $yellow;
    padding: $space-16;
    .e-accordion-toggle {
      background: $yellow;
      color: $black;
      width: 18px;
      height: 18px;
      &__content {
        width: 18px;
        height: 18px;
        padding: 4px;
        &:before {
          width: calc(100% - 8px);
        }
        &:after {
          height: calc(100% - 8px);
        }
      }
    }
  }
  &__pay-modal {
    &.e-modal {
      align-items: flex-end;
      .e-modal__content {
        width: 100%;
        height: 300px;
        padding: 24px;
        overflow: hidden;
      }
      .e-modal__close {
        svg {
          width: 16px;
          height: auto;
        }
      }
    }
    &-title {
      padding: 12px 0;
      margin-bottom: 24px;
      border-bottom: 1px solid $yellow;
    }
    &-buttons {
      display: flex;
      justify-content: center;
      padding: 16px;
      .e-site-button {
        width: 160px;
      }
    }
  }
  @include tablet-landscape {
    @include height-except-header;
    display: flex;
    flex-direction: column;
    &__header {
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: grid-width(1);
    }
    &__title {
      margin-bottom: 0;
    }
    &__help {
      .e-icon {
        float: left;
        margin-right: 4px;
      }
      &-text {
        width: grid-width(5);
        @include h11;
        > a {
          text-decoration: underline;
        }
      }
    }
    &__wrapper {
      flex-grow: 1;
      display: flex;
    }
    &__side {
      height: 100%;
      width: grid-width(8);
      margin-right: 24px;
      flex-shrink: 0;
      overflow-y: visible;
    }
    &__step {
      &-side {
        margin-right: 32px;
      }
    }
    &__main {
      position: static;
      width: auto;
      flex-grow: 1;
      padding-right: grid-width(1);
      overflow-y: visible;
      &-content {
        padding: 0 0 40px 0;
      }
    }
    &__sections {
      padding: 0 grid-width(2);
    }
    &__detail-toggle {
      display: none;
    }
    &__deposit-bar {
      display: block;
      @include grid-block(13, 12);
      padding: $space-20 grid-width(1, false);
      background: $grey-neutral;
      color: $white;
      &-kv {
        @include h7;
        color: $yellow;
        margin-top: 2px !important;
      }
      .e-label-value {
        + .e-label-value,
        #{$c}__deposit-bar-description {
          margin-top: 8px;
        }
      }
    }
    &__pay-modal {
      &.e-modal {
        align-items: center;
        .e-modal__content {
          width: grid-width(8);
        }
      }
    }
  }
  @keyframes step-icon-ani {
    0% {
      transform: translate(-50%, -50%) scale(0.6);
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      transform: translate(-50%, -50%) scale(0.6);
    }
  }
}
.s-personal-center {
  .c-order-detail {
    &__toolbar {
      display: none;
    }
  }
  &.collapsed {
    .c-order-detail {
      &__toolbar {
        display: block;
      }
    }
  }
}
html.rtl {
  .c-order-detail {
    &__help {
      .e-icon {
        float: right;
      }
    }
  }
}
html[lang='ja-JP'] {
  .c-order-detail {
    $c: '.c-order-detail';

    &__step {
      &-disabled {
        &-body {
          font-family: lotusFontFamily('Noto Sans JP Light'), sans-serif;
        }
      }

      &.current:not(.watching) {
        #{$c}__step {
          &-description {
            font-family: lotusFontFamily('Noto Sans JP Light'), sans-serif;
          }
        }
      }
    }

    &__config-section {
      &-description {
        font-family: lotusFontFamily('Noto Sans JP Thin'), sans-serif;
      }

      &-subitem {
        font-family: lotusFontFamily('Noto Sans JP Thin'), sans-serif;
      }
    }
  }
}
html[lang='ko-KR'] {
  .c-order-detail {
    $c: '.c-order-detail';
    &__step {
      &-disabled {
        &-body {
          font-family: lotusFontFamily('Noto Sans KR Light'), sans-serif;
        }
      }

      &.current:not(.watching) {
        #{$c}__step {
          &-description {
            font-family: lotusFontFamily('Noto Sans KR Light'), sans-serif;
          }
        }
      }
    }

    &__config-section {
      &-description {
        font-family: lotusFontFamily('Noto Sans KR Thin'), sans-serif;
      }

      &-subitem {
        font-family: lotusFontFamily('Noto Sans KR Thin'), sans-serif;
      }
    }
  }
}
</style>
