<template>
  <DashboardLayout :fields="fields?.dashboardHeader?.fields" active-menu="order-details">
    <OverallSettings v-bind="$props">
      <div class="c-order-details" v-if="fields">
        <div class="c-order-details__top">
          <div class="c-order-details__top-back">
            <SiteButton v-bind="fields.backButton" />
          </div>
          <div class="c-order-details__top-model">{{ $formatModel(salesOrder ? orderDetail?.salesOrderInfo?.vehicleModel : orderDetail?.carModel) }}</div>
          <div class="c-order-details__top-id">
            {{ $t('Ref') }}:
            <span> {{ salesOrder ? orderDetail?.salesOrderInfo?.orderId : orderDetail?.orderNo }}</span>
          </div>
          <div class="c-order-details__top-vehicle" v-if="vehicleStatus && !cancelled">
            <div class="c-order-details__top-vehicle-btn" @click="showVehicleModal">
              <div class="c-order-details__top-vehicle-status" v-html="vehicleStatus.text" />
              <div class="c-order-details__top-vehicle-icon">
                <Icon :field="vehicleStatus.icon" />
              </div>
            </div>
          </div>
          <div class="c-order-details__top-img">
            <img :src="salesOrder ? orderDetail?.vehicleInfo?.basicInfo?.image : orderDetail?.image" alt="Model image" v-if="orderDetail?.vehicleInfo?.basicInfo?.image || orderDetail?.image" />
          </div>
          <div class="c-order-details__top-steps swiper-container" ref="swiperEl">
            <div class="c-order-details__top-steps-nav" v-if="!cancelled">
              <Icon class="c-order-details__top-steps-nav-left" name="left" />
              <Icon class="c-order-details__top-steps-nav-right" name="right" />
            </div>
            <div class="swiper-wrapper">
              <template v-if="!salesOrder && fields.templateSteps?.length">
                <div class="c-order-details__top-steps-item swiper-slide" v-for="(step, index) in fields.templateSteps" :key="step.id">
                  <div
                    class="c-order-details__top-steps-item-label"
                    :class="{ show: index === currentStepIndex }"
                    v-html="
                      $formatString(fields.stepLabel.value, { count: index + 1, total: String.prototype.padStart.call(fields.orderSteps?.length + 1 + fields.templateSteps?.length - 1, 1, '0') })
                    "
                  />
                  <template v-if="isActive(step, index)">
                    <div class="c-order-details__top-steps-item-title">
                      <div v-html="getStateTitle(step, index)" />
                    </div>
                    <div class="c-order-details__top-steps-item-line">
                      <img :src="fields.stepActiveIcon?.value.src" v-if="index === currentStepIndex" />
                      <Icon :svg="fields.stepFinishedIcon?.value.svgCode" v-else-if="index < currentStepIndex" />
                      <div class="c-order-details__top-steps-item-line-icon default" v-else></div>
                    </div>
                    <div class="c-order-details__top-steps-item-description" :class="{ finished: index < currentStepIndex }">
                      <div v-html="getStateDescription(step, index)" />
                    </div>
                    <div class="c-order-details__top-steps-item-btn">
                      <SiteButton
                        v-if="!isNullOrEmpty(step.fields?.stepButton) && getStateButtonText(step, index)"
                        :fields="merge({}, step.fields?.stepButton?.fields, getStateButtonText(step, index))"
                        @click="onProcessOrder(index)"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div class="c-order-details__top-steps-item-title">
                      <JssRichText :field="step.fields?.disabledTitle" />
                    </div>
                    <div class="c-order-details__top-steps-item-line">
                      <img :src="fields.stepActiveIcon?.value.src" v-if="index === currentStepIndex" />
                      <Icon :svg="fields.stepFinishedIcon?.value.svgCode" v-else-if="index < currentStepIndex" />
                      <div class="c-order-details__top-steps-item-line-icon default" v-else></div>
                    </div>
                    <div class="c-order-details__top-steps-item-description" :class="{ finished: index < currentStepIndex }">
                      <JssRichText :field="step.fields?.disabledBody" />
                    </div>
                  </template>
                </div>
              </template>
              <div class="c-order-details__top-steps-item swiper-slide" v-else>
                <div class="c-order-details__top-steps-item-label" v-html="$formatString(fields.stepLabel.value, { count: '1', total: String.prototype.padStart.call(totalStepCount, 1, '0') })" />
                <div class="c-order-details__top-steps-item-title">
                  <JssRichText :field="salesOrder ? fields.salesOrderStartStep.fields.stepTitle : fields.reservationStartStep.fields.stepTitle" />
                </div>
                <div class="c-order-details__top-steps-item-line" :class="{ close: cancelled || financeCancel }">
                  <Icon :svg="fields.stepFinishedIcon?.value.svgCode" />
                </div>
                <div class="c-order-details__top-steps-item-description finished">
                  <JssRichText :field="salesOrder ? fields.salesOrderStartStep.fields.stepDescription : fields.reservationStartStep.fields.stepDescription" />
                </div>
              </div>
              <template v-if="cancelled">
                <div class="c-order-details__top-steps-item swiper-slide current">
                  <div class="c-order-details__top-steps-item-label" :class="{ show: currentStepIndex === index }" v-html="$formatString(fields.stepLabel.value, { count: '2', total: '2' })" />
                  <div class="c-order-details__top-steps-item-title">
                    <JssRichText :field="fields.cancelStep.fields.stepTitle" />
                  </div>
                  <div class="c-order-details__top-steps-item-line cancel">
                    <Icon :svg="fields.stepCancelIcon?.value.svgCode" />
                  </div>
                  <div class="c-order-details__top-steps-item-description">
                    <JssRichText :field="fields.cancelStep.fields.stepDescription" />
                  </div>
                  <div class="c-order-details__top-steps-item-btn">
                    <SiteButton v-bind="fields.cancelStep.fields.stepButton" />
                  </div>
                </div>
              </template>
              <template v-else-if="orderDetail?.stockOrder">
                <div class="c-order-details__top-steps-item swiper-slide current">
                  <div class="c-order-details__top-steps-item-label" :class="{ show: currentStepIndex === index }" v-html="$formatString(fields.stepLabel.value, { count: '2', total: '2' })" />
                  <div class="c-order-details__top-steps-item-title">
                    <JssRichText :field="fields.UKStep.fields.stepTitle" />
                  </div>
                  <div class="c-order-details__top-steps-item-line cancel">
                    <img :src="fields.stepActiveIcon?.value.src" />
                  </div>
                  <div class="c-order-details__top-steps-item-delivery" v-if="vehicleStatus?.showDeliveryTime && deliveryTime">{{ `${$t('Expected by')} ${deliveryTime}` }}</div>
                  <div class="c-order-details__top-steps-item-description">
                    <JssRichText :field="fields.UKStep.fields.stepDescription" />
                  </div>
                  <div class="c-order-details__top-steps-item-btn">
                    <SiteButton v-bind="fields.UKStep.fields.stepButton" />
                  </div>
                </div>
              </template>
              <template v-else-if="isFleet">
                <div class="c-order-details__top-steps-item swiper-slide current">
                  <div class="c-order-details__top-steps-item-label show" v-html="$formatString(fields.stepLabel.value, { count: '2', total: '2' })" />
                  <div class="c-order-details__top-steps-item-title">
                    <JssRichText :field="fields.fleetOrderStepTitle" />
                  </div>
                  <div class="c-order-details__top-steps-item-line cancel">
                    <img :src="fields.stepActiveIcon?.value.src" />
                  </div>
                  <div class="c-order-details__top-steps-item-description">
                    <div class="c-order-details__top-steps-item-description-time">{{ orderDetail?.vehicleInfo?.deliveryInfo?.preferredDeliveryTime }}</div>
                    <div class="c-order-details__top-steps-item-description-location">{{ orderDetail?.vehicleInfo?.deliveryInfo?.deliveryLocation }}</div>
                    <div class="c-order-details__top-steps-item-description-center">{{ orderDetail?.vehicleInfo?.deliveryInfo?.deliveryCenter }}</div>
                    <div class="c-order-details__top-steps-item-description-time">{{ orderDetail?.vehicleInfo?.deliveryInfo?.customerName }}</div>
                  </div>
                </div>
              </template>
              <template v-else-if="financeLevel2 && !changeFinance">
                <div class="c-order-details__top-steps-item swiper-slide" :class="[{ current: isCurrent(step, index) }]" v-for="(step, index) in fields.financeOrderSteps" :key="step.id">
                  <div
                    class="c-order-details__top-steps-item-label"
                    :class="{ show: currentStepIndex === index && salesOrder }"
                    v-html="
                      $formatString(fields.stepLabel.value, {
                        count: String.prototype.padStart.call(currentStepIndex + 2, 1, '0'),
                        total: String.prototype.padStart.call(totalStepCount, 1, '0')
                      })
                    "
                  />
                  <template v-if="isActive(step, index)">
                    <div class="c-order-details__top-steps-item-title">
                      <div v-html="getStateTitle(step, index)" />
                    </div>
                    <div class="c-order-details__top-steps-item-line">
                      <Icon :svg="fields.stepCancelIcon?.value.svgCode" v-if="financeCancel" />
                      <img :src="fields.stepActiveIcon?.value.src" v-else-if="index === currentStepIndex" />
                      <Icon :svg="fields.stepFinishedIcon?.value.svgCode" v-else-if="index < currentStepIndex" />
                      <div class="c-order-details__top-steps-item-line-icon default" v-else></div>
                    </div>
                    <div class="c-order-details__top-steps-item-description" :class="{ finished: index < currentStepIndex }">
                      <div v-html="getStateDescription(step, index)" />
                    </div>
                    <div class="c-order-details__top-steps-item-btn">
                      <SiteButton v-if="!isNullOrEmpty(step.fields?.stepButton)" :fields="merge({}, step.fields?.stepButton?.fields, getStateButtonText(step, index))" @click="onProcessOrder(index)" />
                    </div>
                  </template>
                  <template v-else>
                    <div class="c-order-details__top-steps-item-title">
                      <JssRichText :field="step.fields?.disabledTitle" />
                    </div>
                    <div class="c-order-details__top-steps-item-line">
                      <img :src="fields.stepActiveIcon?.value.src" v-if="index === currentStepIndex" />
                      <Icon :svg="fields.stepFinishedIcon?.value.svgCode" v-else-if="index < currentStepIndex" />
                      <div class="c-order-details__top-steps-item-line-icon default" v-else></div>
                    </div>
                    <div class="c-order-details__top-steps-item-description" :class="{ finished: index < currentStepIndex }">
                      <JssRichText :field="step.fields?.disabledBody" />
                    </div>
                  </template>
                </div>
              </template>
              <template v-else>
                <template v-if="changeFinance">
                  <div class="c-order-details__top-steps-item swiper-slide">
                    <div
                      class="c-order-details__top-steps-item-label show"
                      v-html="$formatString(fields.stepLabel.value, { count: '1', total: String.prototype.padStart.call(totalStepCount, 1, '0') })"
                    />
                    <div class="c-order-details__top-steps-item-title">
                      <JssRichText :field="fields.changeFinanceStep.fields.stepTitle" />
                    </div>
                    <div class="c-order-details__top-steps-item-line">
                      <img :src="fields.stepActiveIcon?.value.src" />
                    </div>
                    <div class="c-order-details__top-steps-item-description">
                      <JssRichText :field="fields.changeFinanceStep.fields.stepDescription" />
                    </div>
                    <div class="c-order-details__top-steps-item-btn" @click="onOpenFinanceCalculator">
                      <SiteButton v-bind="fields.changeFinanceStep.fields.stepButton" />
                    </div>
                  </div>
                </template>
                <div class="c-order-details__top-steps-item swiper-slide" :class="[{ current: isCurrent(step, index) }]" v-for="(step, index) in fields.orderSteps" :key="step.id">
                  <div
                    class="c-order-details__top-steps-item-label"
                    :class="{ show: !changeFinance && currentStepIndex === index && salesOrder }"
                    v-html="
                      $formatString(fields.stepLabel.value, {
                        count: String.prototype.padStart.call(currentStepIndex + 2, 1, '0'),
                        total: String.prototype.padStart.call(totalStepCount, 1, '0')
                      })
                    "
                  />
                  <template v-if="isActive(step, index) && salesOrder">
                    <div class="c-order-details__top-steps-item-title">
                      <div v-html="getStateTitle(step, index)" />
                    </div>
                    <div class="c-order-details__top-steps-item-line">
                      <img :src="fields.stepActiveIcon?.value.src" v-if="!changeFinance && index === currentStepIndex" />
                      <Icon :svg="fields.stepFinishedIcon?.value.svgCode" v-else-if="!changeFinance && index < currentStepIndex" />
                      <div class="c-order-details__top-steps-item-line-icon default" v-else></div>
                    </div>
                    <div class="c-order-details__top-steps-item-delivery" v-if="index === totalStepCount - 2 && vehicleStatus?.showDeliveryTime && deliveryTime">
                      {{ `${$t('Expected by')} ${deliveryTime}` }}
                    </div>
                    <div class="c-order-details__top-steps-item-description" :class="{ finished: index < currentStepIndex }">
                      <div v-html="getStateDescription(step, index)" />
                    </div>
                    <div class="c-order-details__top-steps-item-btn">
                      <SiteButton
                        v-if="!isNullOrEmpty(step.fields?.stepButton) && getStateButtonText(step, index)"
                        :fields="merge({}, step.fields?.stepButton?.fields, getStateButtonText(step, index))"
                        @click="onProcessOrder(index)"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div class="c-order-details__top-steps-item-title">
                      <JssRichText :field="step.fields?.disabledTitle" />
                    </div>
                    <div class="c-order-details__top-steps-item-line">
                      <img :src="fields.stepActiveIcon?.value.src" v-if="!changeFinance && index === currentStepIndex && salesOrder" />
                      <Icon :svg="fields.stepFinishedIcon?.value.svgCode" v-else-if="!changeFinance && index < currentStepIndex && salesOrder" />
                      <div class="c-order-details__top-steps-item-line-icon default" v-else></div>
                    </div>
                    <div class="c-order-details__top-steps-item-delivery" v-if="index === totalStepCount - 2 && vehicleStatus?.showDeliveryTime && deliveryTime">
                      {{ `${$t('Expected by')} ${deliveryTime}` }}
                    </div>
                    <div class="c-order-details__top-steps-item-description" :class="{ finished: index < currentStepIndex }">
                      <JssRichText :field="step.fields?.disabledBody" />
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="c-order-details__bottom" v-if="orderDetail?.reservationInfo || orderDetail?.salesOrderInfo">
          <div class="c-order-details__bottom-tabs">
            <JssRichText
              class="c-order-details__bottom-tabs-item"
              v-for="item in fields.tabs"
              :key="item.id"
              :class="{ active: selectedTab == $settingValue(item.fields.type) }"
              :field="item.fields.text"
              @click="changeTab(item)"
            />
          </div>
          <div class="c-order-details__bottom-main">
            <template v-if="selectedTab === 'images'">
              <div class="c-order-details__bottom-main-car">
                <div class="c-order-details__bottom-main-car-left">
                  <img :src="orderDetail?.vehicleInfo?.basicInfo?.image" alt="Model image" v-if="orderDetail?.vehicleInfo?.basicInfo?.image" />
                </div>
                <div class="c-order-details__bottom-main-car-right">
                  <div class="c-order-details__bottom-main-car-right-model">Lotus {{ $formatModel(orderDetail?.salesOrderInfo?.vehicleModel) }}</div>
                  <div class="c-order-details__bottom-main-car-right-num">{{ $formatInclMoney(orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency) }}</div>
                  <div class="c-order-details__bottom-main-car-right-text">{{ $t('Total on the road price') }}</div>
                </div>
              </div>
            </template>
            <template v-if="selectedTab === 'details'">
              <div class="c-order-details__bottom-main-item">
                <div class="c-order-details__bottom-main-item-label">
                  {{ orderDetail?.reservationInfo && !salesOrder ? $tu('Reservation') : $tu('Order') }}
                </div>
                <div class="c-order-details__bottom-main-item-list">
                  <div class="c-order-details__bottom-main-item-list-item" v-if="!isFleet">
                    <div class="c-order-details__bottom-main-item-list-item-label">
                      {{ $tu('Reference') }}
                    </div>
                    <div class="c-order-details__bottom-main-item-list-item-text">
                      {{ salesOrder ? orderDetail?.salesOrderInfo?.orderId : orderDetail?.orderNo }}
                    </div>
                  </div>
                  <div class="c-order-details__bottom-main-item-list-item" v-if="salesOrder && !isFleet">
                    <div class="c-order-details__bottom-main-item-list-item-label"></div>
                    <div class="c-order-details__bottom-main-item-list-item-text"></div>
                  </div>
                  <div class="c-order-details__bottom-main-item-list-item" v-if="!isFleet">
                    <div class="c-order-details__bottom-main-item-list-item-label">
                      {{ orderDetail?.reservationInfo && !salesOrder ? $tu('Reservation date') : $tu('Order date') }}
                    </div>
                    <div class="c-order-details__bottom-main-item-list-item-text">
                      {{ salesOrder ? orderDetail?.salesOrderInfo?.createTime : orderDetail?.createTime }}
                    </div>
                  </div>
                  <div class="c-order-details__bottom-main-item-list-item" v-if="salesOrder || isFleet">
                    <div class="c-order-details__bottom-main-item-list-item-label">
                      {{ $tu('Last update') }}
                    </div>
                    <div class="c-order-details__bottom-main-item-list-item-text">
                      {{
                        orderDetail?.reservationInfo
                          ? orderDetail?.reservationInfo?.paymentTime
                            ? orderDetail?.reservationInfo?.paymentTime
                            : orderDetail?.reservationInfo?.createTime
                          : orderDetail?.salesOrderInfo?.paymentTime
                          ? orderDetail?.salesOrderInfo?.paymentTime
                          : orderDetail?.salesOrderInfo?.createTime
                      }}
                    </div>
                  </div>
                  <template v-if="!isFleet">
                    <div class="c-order-details__bottom-main-item-list-item c-order-details__bottom-main-invoices" v-for="item in orderDetail?.invoiceInfoList" :key="item.invoiceNo">
                      <div class="c-order-details__bottom-main-item-list-item-label">
                        {{ $tu('Invoices') }}
                      </div>
                      <div class="c-order-details__bottom-main-item-list-item-text">
                        <div class="c-order-details__bottom-main-invoices-id">{{ $t('Invoice') }} {{ $isNullOrEmpty(item.invoiceNo) ? '' : item.invoiceNo }}</div>
                        <div class="c-order-details__bottom-main-invoices-amount">
                          {{ $formatInclMoney(item.invoiceAmount, orderDetail?.currency) }}
                        </div>
                        <div class="c-order-details__bottom-main-invoices-date">
                          {{ item.invoiceDate }}
                        </div>
                        <div class="c-order-details__bottom-main-invoices-file">
                          <a :href="item.pdfUrl" target="_blank"> <Icon name="invoice-download" /> {{ $t('Download invoice') }} </a>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="c-order-details__bottom-main-item">
                <div class="c-order-details__bottom-main-item-label">{{ $tu('Personal details') }}</div>
                <div class="c-order-details__bottom-main-item-list">
                  <div class="c-order-details__bottom-main-item-list-item" v-if="salesOrder || isFleet">
                    <div class="c-order-details__bottom-main-item-list-item-label">
                      {{ $tu('Phone number') }}
                    </div>
                    <div class="c-order-details__bottom-main-item-list-item-text">{{ userPhone }}</div>
                  </div>
                  <div class="c-order-details__bottom-main-item-list-item">
                    <div class="c-order-details__bottom-main-item-list-item-label">
                      {{ $tu('Email address') }}
                    </div>
                    <div class="c-order-details__bottom-main-item-list-item-text">
                      {{ isFleet ? userInfo?.email : orderDetail?.userInfo?.email }}
                    </div>
                  </div>
                  <div class="c-order-details__bottom-main-item-list-item" v-if="!isFleet">
                    <div class="c-order-details__bottom-main-item-list-item-label">
                      {{ $tu('Invoice / Billing address') }}
                    </div>
                    <div class="c-order-details__bottom-main-item-list-item-text">
                      {{
                        salesOrder
                          ? $buildAddress(
                              orderDetail?.salesOrderInfo?.taxpayerStreet,
                              orderDetail?.salesOrderInfo?.taxpayerZipCode,
                              orderDetail?.salesOrderInfo?.taxpayerCity,
                              orderDetail?.salesOrderInfo?.countryName
                            )
                          : $buildAddress(orderDetail?.billingAddress?.street, orderDetail?.billingAddress?.zipCode, orderDetail?.billingAddress?.city, orderDetail?.billingAddress?.name)
                      }}
                    </div>
                  </div>
                  <div class="c-order-details__bottom-main-item-list-item" v-if="salesOrder && !isFleet">
                    <div class="c-order-details__bottom-main-item-list-item-label">
                      {{ $tu('Shipping address') }}
                    </div>
                    <div class="c-order-details__bottom-main-item-list-item-text">
                      {{
                        $buildAddress(
                          orderDetail?.vehicleInfo?.deliveryInfo?.street,
                          orderDetail?.vehicleInfo?.deliveryInfo?.zipCode,
                          orderDetail?.vehicleInfo?.deliveryInfo?.city,
                          orderDetail?.vehicleInfo?.deliveryInfo?.countryName
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-order-details__bottom-main-item" v-if="salesOrder || isFleet">
                <div class="c-order-details__bottom-main-item-label">{{ $tu('Price buildup') }}</div>
                <div class="c-order-details__bottom-main-item-list">
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-subtitle">
                      <div>{{ $tu('Package') }}</div>
                      <div v-if="!isFleet">{{ $tu('Base price') }}</div>
                    </div>
                    <div class="c-order-details__bottom-main-price-content">
                      <LabelValue
                        class="c-order-details__bottom-main-price-item"
                        :label="$formatModel(orderDetail?.salesOrderInfo?.vehicleModel)"
                        :value="isFleet ? '' : $formatInclMoney(priceInfo?.find((x) => equalString(x.elementName, 'Base Retail Price'))?.price, orderDetail?.currency)"
                        v-if="priceInfo?.length"
                      />
                    </div>
                  </div>
                  <div class="c-order-details__bottom-main-price" v-for="(feature, index) in vehicleDetail" :key="index">
                    <template v-if="feature.infos?.length > 0">
                      <div class="c-order-details__bottom-main-price-subtitle">{{ feature.type }}</div>
                      <div class="c-order-details__bottom-main-price-content">
                        <template v-for="(info, j) in feature.infos" :key="j">
                          <div class="c-order-details__bottom-main-price-value" v-html="info.label" />
                          <LabelValue
                            class="c-order-details__bottom-main-price-item"
                            :label="info.value"
                            :value="isFleet ? '' : info.price >= 0 ? $formatInclMoney(info.price, info.currency) : $tl('Included')"
                          />
                        </template>
                      </div>
                    </template>
                  </div>
                  <div class="c-order-details__bottom-main-total" v-if="!isFleet">
                    <LabelValue class="c-order-details__bottom-main-total-bottom" :label="$tu('Total')" :value="$formatInclMoney(totalAmount, orderDetail?.currency)" />
                  </div>
                  <div class="c-order-details__bottom-main-configuration" v-if="!isFleet">
                    <div class="c-order-details__bottom-main-configuration-title">{{ $tu('Your configuration') }}</div>
                    <LabelValue :label="$t('On the road price')" :value="$formatInclMoney(totalAmount, orderDetail?.currency)" />
                    <div class="c-order-details__bottom-main-configuration-block">
                      <LabelValue :label="priceItem.label" :value="$formatInclMoney(priceItem.price, priceItem.currency)" v-for="priceItem in priceInfo" :key="priceItem.elementName" />
                    </div>
                    <template v-if="benefitsList?.length > 0">
                      <LabelValue :label="`${$t('Offer')}/${$t('Service')}`" :value="$formatInclMoney(benefitsAmount, orderDetail?.currency)" />
                      <div class="c-order-details__bottom-main-configuration-block">
                        <LabelValue
                          :label="benefits.name"
                          :value="Number(benefits.price) > 0 ? $formatInclMoney(benefits.price, currency) : $t('Included').toLowerCase()"
                          v-for="benefits in benefitsList"
                          :key="benefits.rightsId"
                        />
                      </div>
                    </template>
                    <LabelValue :label="$t('Total amount payable')" :value="$formatInclMoney(totalAmountPayable, orderDetail?.currency)" />
                    <div class="c-order-details__bottom-main-configuration-block">
                      <LabelValue :label="$t('On the road price')" :value="$formatInclMoney(totalAmount, orderDetail?.currency)" />
                      <LabelValue :label="$t('Total Deposit')" :value="`- ${$formatInclMoney(orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency)}`" />
                      <LabelValue theme="yellow" :label="$t('Customer balance')" :value="$formatInclMoney(orderDetail?.paymentInfo?.balancePaymentAmount, currency)" v-if="totalAmountPayable === 0" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="c-order-details__bottom-main-item"
                v-if="
                  financeLevel2 &&
                    !isFleet &&
                    !isNullOrEmpty(financeInfo) &&
                    (equalString(financeInfo.applicationStatus, FINANCE_STATUES.Approved) ||
                      equalString(financeInfo.applicationStatus, FINANCE_STATUES.ContractSigned) ||
                      equalString(financeInfo.applicationStatus, FINANCE_STATUES.Disbursed))
                "
              >
                <div class="c-order-details__bottom-main-item-label">{{ loanFlag ? $tu('Loan Balloon KM') : $tu('Linear loan') }}</div>
                <div class="c-order-details__bottom-main-item-list finance">
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $t('Total on the road price') }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ $formatInclMoney(orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency) }}</div>
                  </div>
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $t('Down payment') }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ $formatInclMoney(financeInfo.downPayment, currency) }}</div>
                  </div>
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $t('Duration') }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ `${financeInfo.duration} ${$t('Months')}` }}</div>
                  </div>
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $t('Yearly mileage') }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ `${financeInfo.mileage} Km` }}</div>
                  </div>
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $t('First installment') }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ $formatInclMoney(financeInfo.firstInstallment, currency) }}</div>
                  </div>
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $formatString($t('##no## monthly instalments of'), { no: financeInfo.duration - 2 }) }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ $formatInclMoney(financeInfo.monthlyPrice, currency) }}</div>
                  </div>
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $t('Calculated final instalment') }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ $formatInclMoney(financeInfo.lastInstalment, currency) }}</div>
                  </div>
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $t('Financed amount') }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ $formatInclMoney(financeInfo.financedAmount, currency) }}</div>
                  </div>
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $t('Total cost of credit') }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ $formatInclMoney(financeInfo.totalCostofCredit, currency) }}</div>
                  </div>
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $t('Fixed borrowing rate p.a.') }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ financeInfo.nominalInterestRate }}%</div>
                  </div>
                  <div class="c-order-details__bottom-main-price">
                    <div class="c-order-details__bottom-main-price-label">{{ $t('Effective interest rate') }}</div>
                    <div class="c-order-details__bottom-main-price-text">{{ financeInfo.effectiveInterestRate }}%</div>
                  </div>
                  <div class="c-order-details__bottom-main-configuration">
                    <div class="c-order-details__bottom-main-configuration-title">{{ $tu('Your configuration') }}</div>
                    <LabelValue :label="$t('On the road price')" :value="$formatInclMoney(totalAmount, orderDetail?.currency)" />
                    <div class="c-order-details__bottom-main-configuration-block">
                      <LabelValue :label="priceItem.label" :value="$formatInclMoney(priceItem.price, priceItem.currency)" v-for="priceItem in priceInfo" :key="priceItem.elementName" />
                    </div>
                    <template v-if="benefitsList?.length > 0">
                      <LabelValue :label="`${$t('Offer')}/${$t('Service')}`" :value="$formatInclMoney(benefitsAmount, orderDetail?.currency)" />
                      <div class="c-order-details__bottom-main-configuration-block">
                        <LabelValue
                          :label="benefits.name"
                          :value="Number(benefits.price) > 0 ? $formatInclMoney(benefits.price, currency) : $tl('Included')"
                          v-for="benefits in benefitsList"
                          :key="benefits.rightsId"
                        />
                      </div>
                    </template>
                    <LabelValue :label="$t('Total amount payable')" :value="$formatInclMoney(totalAmountPayable, orderDetail?.currency)" />
                    <div class="c-order-details__bottom-main-configuration-block">
                      <LabelValue :label="$t('On the road price')" :value="$formatInclMoney(totalAmount, orderDetail?.currency)" />
                      <LabelValue :label="$t('Total Deposit')" :value="`- ${$formatInclMoney(orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency)}`" />
                      <LabelValue theme="yellow" :label="$t('Customer balance')" :value="$formatInclMoney(orderDetail?.paymentInfo?.balancePaymentAmount, currency)" v-if="totalAmountPayable === 0" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </OverallSettings>
    <Modal class="c-order-details__vehicle-modal" ref="modalVehicleRef" closable>
      <div class="c-order-details__vehicle-modal-eyebrow c-order-details__vehicle-modal-text" v-html="vehicleStatus.modalEyebrow" />
      <div
        class="c-order-details__vehicle-modal-title c-order-details__vehicle-modal-text"
        v-html="$formatString(vehicleStatus.modalTitle, { carModal: orderDetail?.vehicleInfo?.basicInfo?.series })"
      />
      <div class="c-order-details__vehicle-modal-media">
        <AdaptiveMedia :field="vehicleStatus.modalMedia" :isBackground="false" />
      </div>
      <div class="c-order-details__vehicle-modal-body c-order-details__vehicle-modal-text" v-html="$formatString(vehicleStatus.modalBody, { carModal: orderDetail?.vehicleInfo?.basicInfo?.series })" />
      <button class="e-site-button primary-button theme-yellow c-order-details__vehicle-modal-btn" type="button" @click="hideVehicleModal">
        {{ vehicleStatus.buttonText }}
      </button>
    </Modal>
    <Modal class="c-order-details__finance-modal" ref="financeModalRef" closable>
      <JssRichText class="c-order-details__finance-modal-title fs-26 tl-fs-32 mg-t-96 mg-b-48" :field="fields.applyFinanceTitle" />
      <BackgroundImage class="mg-b-40" :image="fields.applyFinanceImage" :is-background="false" />
      <JssRichText class="c-order-details__finance-modal-body mg-b-56" :field="fields.applyFinanceBody" />
      <div class="c-order-details__finance-modal-btn">
        <SiteButton v-bind="fields.applyFinanceButton" @click="onSubmitFinance" />
      </div>
    </Modal>
    <Modal class="c-order-details__finance-close-modal" ref="financeCancelModalRef" closable>
      <div class="c-order-details__finance-close-modal-title fs-26" v-html="financeCancelStatus?.cancelledTitle" />
      <div class="c-order-details__finance-close-modal-subtitle fs-14" v-html="financeCancelStatus?.cancelledSubtitle" />
      <div class="c-order-details__finance-close-modal-body fs-16" v-html="financeCancelStatus?.cancelledDescription" />
    </Modal>
    <Modal class="c-order-details__finance-close-modal" ref="financeRejectedModalRef" closable>
      <div class="c-order-details__finance-close-modal-title fs-26" v-html="financeCancelStatus?.rejectedTitle" />
      <div class="c-order-details__finance-close-modal-subtitle fs-14" v-html="financeCancelStatus?.rejectedSubtitle" />
      <div class="c-order-details__finance-close-modal-body fs-16" v-html="financeCancelStatus?.rejectedDescription" />
    </Modal>
    <NoticeModal v-bind="fields.localeRedirectModal" ref="orderLocaleRedirectModalRef" />
    <FinanceCalculator ref="financeCalculatorRef" />
    <final-pay-modal ref="paymentModalRef" v-bind="fields.finalPaymentModal" />
  </DashboardLayout>
</template>
<script>
import { onMounted, inject, toRefs, reactive, computed, onBeforeUnmount, nextTick, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import services from '@/services';
import { ORDER_STATUES, FINANCE_STATUES } from '@/utils/constants';
import { updateDictionaryValue } from '@/services/dictService';
import { getCarSeries } from '@/services/siteService';
import { getSantanderLoanConfig } from '@/services/financeService';
import { openWindow } from '@/utils/dom-utils';
import { settingValue, getGlobalConfigs, getPageAlpha2Code, getCurrentAlpha2Code } from '@/utils/site-utils';
import { redirectTo, getQueryStrings, redirectToLang } from '@/utils/uri-utils';
import { qtUtils } from '@/utils/ali-tracker-utils';
import { equalString, formatString } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { sum } from '@/utils/math-utils';
import useAppStore from '@/store/appStore';
import * as querystring from 'querystring';
import BUS_EVENTS from '@/utils/bus-events';
import { addModalClass, removeModalClasses } from '@/utils/dom-utils';
import api from '@/api';
import { merge } from 'lodash';
import { useI18n } from 'vue-i18n';
import Swiper from 'swiper';
export default {
  name: 'OrderDetails',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const appStore = useAppStore();
    const toast = inject('toast');
    const loading = inject('loading');
    const $bus = inject('$bus');
    const route = useRoute();
    const router = useRouter();
    const { orderId } = route?.query || {};
    const [event, verifykey] = getQueryStrings('event', 'verifykey');
    const [orderListLink] = getGlobalConfigs(props.page, 'orderListLink');
    const { t } = useI18n();
    let res, ex;
    let swiper, confirmPayment, scfSimulationTimeout;
    const state = reactive({
      orderId,
      totalStepCount: 0,
      watching: false,
      orderDetail: null,
      swiperEl: null,
      currentStepIndex: 0,
      cancelled: false,
      carSeries: null,
      priceInfo: [],
      vehicleDetail: [],
      invoiceInfo: null,
      currency: null,
      features: [],
      totalAmount: null,
      totalAmountPayable: null,
      benefitsList: [],
      benefitsAmount: null,
      orderStatus: null,
      selectedTab: 'details',
      modalPayRef: null,
      payWidgetRef: null,
      modalBankRef: null,
      modalVehicleRef: null,
      vehicleStatus: null,
      financeInfo: null,
      financeCancel: false,
      simulation: null,
      assetInfo: null,
      financeLevel2: false,
      financeModalRef: null,
      financeCancelModalRef: null,
      financeRejectedModalRef: null,
      financeCancelStatus: null,
      loanFlag: true,
      isFinance: false,
      orderLocaleRedirectModalRef: null,
      salesOrder: false,
      changeFinance: false,
      financeCalculatorInitialized: false,
      orderListInfo: null,
      financeCalculatorRef: null,
      paymentModalRef: null,
      isFleet: false,
      userInfo: appStore?.loginInfo,
      deliveryTime: null,
      userPhone: null
    });
    const computes = {
      bankInfo: computed(() => querystring.parse(props.fields.bankInfo.value))
    };

    watch(
      () => appStore.orderInfo,
      async (v) => {
        state.orderListInfo = v.find((o) => equalString(o.id, orderId));

        if (state.orderListInfo?.salesOrder) {
          state.salesOrder = true;
          [res, ex] = await api.order.intactDetail(null, { id: orderId });
        } else {
          state.salesOrder = false;
          [res, ex] = await api.order.detail(null, { id: orderId });
        }
        if (ex) {
          await toast.showEx(ex);
          if (ex.code !== 60000000 && ex.code !== 60000009) {
            setTimeout(() => {
              redirectTo({ url: orderListLink?.value?.href, router });
            }, 3000);
            return;
          }
        }
        if (equalString(event, 'signing_complete') && res?.vehicleOrderSignVO?.isSign !== 20) {
          await _methods.watching();
          return;
        }
        const [needRedirect, targetLang] = services.order.checkOrderLang(props.page, res?.salesOrderInfo?.countryCode);
        if (needRedirect) {
          await state.orderLocaleRedirectModalRef.open();
          redirectToLang(targetLang);
        }
        await _methods.setDetail(res);
      },
      {
        deep: true
      }
    );

    const _methods = {
      async setDetail(res) {
        if (res?.transparentNodesVO?.node?.nodeTime) {
          const time = new Date(Number(res?.transparentNodesVO?.node?.nodeTime));
          const monthNumber = time.getUTCMonth();
          let month = '';
          let year = time.getUTCFullYear();
          switch (monthNumber) {
            case 0:
              {
                month = t('February');
              }
              break;
            case 1:
              {
                month = t('March');
              }
              break;
            case 2:
              {
                month = t('April');
              }
              break;
            case 3:
              {
                month = t('May');
              }
              break;
            case 4:
              {
                month = t('June');
              }
              break;
            case 5:
              {
                month = t('July');
              }
              break;
            case 6:
              {
                month = t('August');
              }
              break;
            case 7:
              {
                month = t('September');
              }
              break;
            case 8:
              {
                month = t('October');
              }
              break;
            case 9:
              {
                month = t('November');
              }
              break;
            case 10:
              {
                month = t('December');
              }
              break;
            case 11:
              {
                month = t('January');
                year++;
              }
              break;
            default:
              break;
          }

          state.deliveryTime = `${month} ${year}`;
        }
        state.orderDetail = res;
        state.currency = res?.currency;
        state.priceInfo = await updateDictionaryValue(res?.additionalTaxInfo, 'elementName', 'label', (x) => x.visibility);
        if (res?.userInfo?.areaCode && res?.userInfo?.phoneNumber) {
          state.userPhone = `+${res.userInfo.areaCode} ${res.userInfo.phoneNumber}`;
        }
        if (state.salesOrder) {
          state.carSeries = await getCarSeries(res.vehicleInfo?.basicInfo?.series);
          state.vehicleDetail = JSON.parse(!isNullOrEmpty(res?.vehicleInfo?.basicInfo?.infoJson) ? res?.vehicleInfo?.basicInfo?.infoJson : '[]');
          state.invoiceInfo = res?.invoiceInfoList?.length > 0 ? res?.invoiceInfoList[0] : null;
          state.orderStatus = await services.order.getOrderStatus(res?.salesOrderInfo?.orderStatus);
          state.cancelled = [ORDER_STATUES.Refunded, ORDER_STATUES.Canceled, ORDER_STATUES.ExpiredTobeConfirm, ORDER_STATUES.Closed].includes(state.orderStatus?.code);
          if (state.cancelled) {
            state.totalStepCount = 2;
          } else {
            state.totalStepCount += props.fields.orderSteps.length;
          }
          state.vehicleStatus = await services.order.getOrderVehicleStatus(res?.transparentNodesVO?.node?.nodeCode);
          const { paymentSchemeCode } = res?.vehicleInfo?.basicInfo?.paymentInfo;
          const { orderType } = res?.salesOrderInfo;
          const alpha2Code = getPageAlpha2Code(props.page);
          if (equalString(orderType, '200') && equalString(paymentSchemeCode, '200')) {
            state.isFinance = true;
            if (res?.simulationId && alpha2Code !== 'GB') {
              state.totalStepCount = props.fields.financeOrderSteps.length + 1;
              if (state.changeFinance) {
                state.totalStepCount += 1;
              }
              const [financeInfo] = await api.finance.query({ orderId: orderId });
              state.financeInfo = financeInfo;
              state.financeLevel2 = true;
              state.financeCancel = [FINANCE_STATUES.Rejected, FINANCE_STATUES.Cancelled].includes(state.financeInfo?.applicationStatus);
              const alpha2Code = getCurrentAlpha2Code();
              const config = await getSantanderLoanConfig(alpha2Code);
              const [assetRes] = await api.finance.getAssetInfo(null, {
                channelCode: config.channelCode,
                country: alpha2Code,
                model: res?.vehicleInfo?.basicInfo?.filter
              });
              if (!assetRes?.length || isNullOrEmpty(assetRes[0].assetCode)) {
                toast.showError('Asset information failed to load');
              } else {
                state.assetInfo = assetRes[0];
              }
              if (state.carSeries?.financeProduct) {
                state.loanFlag = equalString(state.carSeries?.financeProduct?.name, 'LoanballoonKM');
              }
            }
          } else if (equalString(orderType, '800')) {
            state.isFleet = true;
            if (res?.otdOwnerInfoResp?.contactList?.length && res?.otdOwnerInfoResp?.contactList[0].mobileAreaCode && res?.otdOwnerInfoResp?.contactList[0].phone) {
              state.userPhone = `+${res?.otdOwnerInfoResp?.contactList[0].mobileAreaCode} ${res?.otdOwnerInfoResp?.contactList[0].phone}`;
            }
            await appStore.setLoginInfo(merge(appStore?.loginInfo, res.otdOwnerInfoResp));
          }
          if (state.vehicleStatus) {
            if (!isNullOrEmpty(state.vehicleStatus.modalMedia)) {
              const { fields } = state.vehicleStatus?.modalMedia;
              const modalMedia = {
                mobileImage: { value: fields.mobileImage ?? null },
                mobileRatio: fields.mobileRatio ?? null,
                mobileVideo: { value: fields.mobileVideo ?? '' },
                tabletImage: { value: fields.tabletImage ?? null },
                tabletRatio: fields.tabletRatio ?? null,
                tabletVideo: { value: fields.tabletVideo ?? '' },
                desktopImage: { value: fields.desktopImage ?? null },
                desktopRatio: fields.desktopRatio ?? null,
                desktopVideo: { value: fields.desktopVideo ?? '' },
                autoplay: { value: fields.autoplay ?? false },
                loop: { value: fields.loop ?? false },
                enterAnimation: { value: fields.enterAnimation ?? false }
              };
              state.vehicleStatus.modalMedia.fields = modalMedia;
            }
          }
          const [vehicleDetail] = getQueryStrings('vehicleDetail');
          if (Number(vehicleDetail) && state.vehicleStatus) {
            state.modalVehicleRef.open();
          }
          state.totalAmount =
            state.priceInfo.find((x) => x.elementName === 'Total Price')?.price ??
            Math.max.apply(
              null,
              state.priceInfo.filter((x) => !isNaN(x.price)).map((x) => Number(x.price))
            ) ??
            null;
          state.totalAmountPayable = state.totalAmount - (res?.salesOrderInfo?.totalAmountNumber ?? 0) - (res?.financeAmount ?? 0);
          state.benefitsList = res?.rightsRespVOS?.sort((a, b) => b.price - a.price) ?? [];
          state.benefitsAmount = sum(res?.rightsRespVOS ?? [], 'price');
        } else {
          state.totalStepCount += props.fields.templateSteps?.length;
          state.totalStepCount += props.fields.orderSteps?.length;
        }
        qtUtils.trackPv('my_order_pg', {
          order_number: res?.salesOrderInfo?.orderId,
          model_selection: res?.vehicleInfo?.basicInfo?.model,
          car_package_info: res?.vehicleInfo?.basicInfo?.infoJson,
          order_stage: state.orderStatus?.name
        });
        loading.hide();
      },
      async watching() {
        state.watching = true;
        const [res, ex] = await api.order.intactDetail(null, {
          id: orderId
        });
        if (ex) {
          await toast.showEx(ex);
          return;
        }
        await _methods.setDetail(res);
        if (res?.vehicleOrderSignVO?.isSign !== 20) {
          setTimeout(_methods.watching, 1000);
          return;
        }
        state.watching = false;
      },
      buildSwiper: () => {
        swiper && swiper.destroy(true, true);
        swiper = new Swiper(state.swiperEl, {
          slidesPerView: 'auto',
          navigation: {
            prevEl: '.c-order-details__top-steps-nav-left',
            nextEl: '.c-order-details__top-steps-nav-right',
            disabledClass: 'c-order-details__top-steps-nav-disabled'
          }
        });
      },
      onConfirmFinanceCalculator(e) {
        state.simulation = { selectedProduct: e.selectedProduct, selectedSimulation: e.selectedSimulation };
        state.financeModalRef.open();
      },
      buildScfCfg() {
        const defaultTerms = 60;
        const annualMileage = 15000;
        const retailPrice = Number(state.priceInfo.find((x) => equalString(x.elementName, 'Vehicle Total Price'))?.price);
        let deposit = Math.floor(retailPrice * 0.15);
        const _options = {
          model: state.orderDetail?.vehicleInfo?.basicInfo?.model,
          assetPrice: retailPrice,
          assetImage: state.carImages?.length > 0 ? state.carImages[0] : '',
          term: defaultTerms,
          deposit,
          forceSelection: {
            term: defaultTerms,
            deposit,
            annualMileage
          }
        };
        if (state.simulation?.selectedSimulation?.downPayment.value) {
          merge(_options, {
            term: state.simulation?.selectedSimulation?.duration.value,
            deposit: state.simulation?.selectedSimulation?.downPayment.value,
            forceSelection: {
              deposit: state.simulation?.selectedSimulation?.downPayment.value,
              term: state.simulation?.selectedSimulation?.duration.value,
              monthlyPayment: state.simulation?.selectedSimulation?.monthlyPayment.value
            }
          });
        }
        return _options;
      },
      async initFinanceInfo() {
        if (scfSimulationTimeout) clearTimeout(scfSimulationTimeout);
        state.agentOptions = await services.agent.getSalesAgentOptions(props.page);
        await nextTick();
        if (state.carSeries?.financeLevel2) {
          const [scfSimulation, assetInfo] = await state.financeCalculatorRef.config(_methods.buildScfCfg());
          state.assetInfo = assetInfo;
          $bus.on(BUS_EVENTS.CONFIRM_FINANCE_CALCULATOR, _methods.onConfirmFinanceCalculator);
          if (scfSimulation) {
            scfSimulation.scfQuotingVisibilityUpdated((e) => {
              state.financeCalculatorInitialized = true;
              if (!e.visible) {
                removeModalClasses('santander-open');
              }
            });
            scfSimulation.scfSelectedSimulationUpdated((e) => {
              if (!state.financeCalculatorInitialized || !state.simulation) {
                state.simulation = e;
              }
            });
            scfSimulationTimeout = setTimeout(() => {
              state.simulation = global.scfSimulation;
            }, 5000);
          }
        }
      }
    };

    const methods = {
      equalString,
      isNullOrEmpty,
      merge,
      getStateField(step, index, field) {
        const { scenes } = step.fields || {};
        let result = step.fields[field]?.value;
        if (state.financeLevel2) {
          if (index === 0) {
            if (
              equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Approved) ||
              equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.ContractSigned) ||
              equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Disbursed)
            ) {
              const scene = scenes.find((x) => settingValue(x.fields.type) === 'completed');
              result = scene?.fields[field]?.value;
            } else if (equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Rejected)) {
              const scene = scenes.find((x) => settingValue(x.fields.type) === 'finance_unsuccess');
              result = scene?.fields[field]?.value;
            } else if (equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Cancelled)) {
              const scene = scenes.find((x) => settingValue(x.fields.type) === 'finance_canceled');
              result = scene?.fields[field]?.value;
            }
          }
          if (index === 1 && state.orderDetail?.vehicleOrderSignVO?.isSign === 20) {
            const scene = scenes.find((x) => settingValue(x.fields.type) === 'completed');
            result = scene?.fields[field]?.value;
          }
          if (index === 2 && state.orderStatus && state.orderStatus.code && equalString(state.orderStatus.code, '30')) {
            const scene = scenes.find((x) => settingValue(x.fields.type) === 'completed');
            result = scene?.fields[field]?.value;
          }
          return result;
        } else {
          if (index === 0 && state.orderDetail?.vehicleOrderSignVO?.isSign === 20) {
            const scene = scenes.find((x) => settingValue(x.fields.type) === 'completed');
            result = scene?.fields[field]?.value;
          }
          if (index === 1 && state.orderStatus && state.orderStatus.code && equalString(state.orderStatus.code, '30')) {
            const scene = scenes.find((x) => settingValue(x.fields.type) === 'completed');
            result = scene?.fields[field]?.value;
          }
          return result;
        }
      },
      getStateTitle(step, index) {
        let result = methods.getStateField(step, index, 'title');
        if (isNullOrEmpty(result)) {
          result = props.fields.stepTitle?.value;
        }
        return formatString(result, { step: index > 0 ? index.toString() : ' ' });
      },
      getStateDescription(step, index) {
        return methods.getStateField(step, index, 'description');
      },
      getStateButtonText(step, index) {
        return {
          text: { value: methods.getStateField(step, index, 'buttonText') }
        };
      },
      isCurrent(step, index) {
        let tmp = false;
        if (state.changeFinance) {
          return;
        }
        if (state.salesOrder) {
          if (state.financeLevel2) {
            const finished =
              equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Approved) ||
              equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.ContractSigned) ||
              equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Disbursed);
            if (index === 0) {
              return !finished;
            } else {
              if (index === 1 && [ORDER_STATUES.Deposit, ORDER_STATUES.DepositLock].includes(state.orderDetail?.salesOrderInfo?.orderStatus)) {
                const contractStatus = finished ? state.orderDetail?.salesOrderInfo?.isCanSignContract ?? false : false;
                if (contractStatus) {
                  state.currentStepIndex = index;
                  return true;
                } else {
                  return false;
                }
              }
              tmp = step.fields.orderStatuses.some((x) => state.orderDetail?.salesOrderInfo?.orderStatus === x.fields.code.value);
              if (tmp) {
                state.currentStepIndex = index;
              }
              return tmp;
            }
          } else {
            if (index === 0 && [ORDER_STATUES.Deposit, ORDER_STATUES.DepositLock].includes(state.orderDetail?.salesOrderInfo?.orderStatus)) {
              state.currentStepIndex = index;
              return state.orderDetail?.salesOrderInfo?.isCanSignContract ?? false;
            } else {
              tmp = step.fields.orderStatuses.some((x) => state.orderDetail?.salesOrderInfo?.orderStatus === x.fields.code.value);
              if (tmp) {
                state.currentStepIndex = index;
              }
              return tmp;
            }
          }
        } else {
          if (index === 1) {
            state.currentStepIndex = index;
            tmp = true;
          }
          return tmp;
        }
      },
      isActive(step, index) {
        if (state.changeFinance) {
          return;
        }
        if (state.salesOrder) {
          if (state.financeLevel2) {
            if (index === 0) {
              return !equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.ManualUnderwriting);
            } else {
              return (
                methods.isCurrent(step, index) ||
                (index === 1 && state.orderDetail?.vehicleOrderSignVO?.isSign === 20) ||
                (index === 2 && equalString(state.orderDetail?.salesOrderInfo?.orderStatus, '30'))
              );
            }
          } else {
            return (
              methods.isCurrent(step, index) ||
              (index === 0 && state.orderDetail?.vehicleOrderSignVO?.isSign === 20) ||
              (index === 1 && equalString(state.orderDetail?.salesOrderInfo?.orderStatus, '30'))
            );
          }
        } else {
          return index === 1;
        }
      },
      async onProcessOrder(index) {
        if (state.salesOrder) {
          if (state.watching) return;
          const { orderStatus } = state.orderDetail?.salesOrderInfo ?? {};
          if (state.financeLevel2) {
            switch (index) {
              case 0: {
                if (
                  isNullOrEmpty(state.financeInfo) ||
                  (!equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Rejected) &&
                    !equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Cancelled) &&
                    !equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Approved) &&
                    !equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.ContractSigned) &&
                    !equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Disbursed))
                ) {
                  state.financeModalRef.open();
                  return;
                }
                if (equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Rejected)) {
                  state.financeCancelStatus = await services.finance.getFinanceStatus(FINANCE_STATUES.Rejected);
                  state.financeRejectedModalRef.open();
                  return;
                }
                if (equalString(state.financeInfo?.applicationStatus, FINANCE_STATUES.Cancelled)) {
                  state.financeCancelStatus = await services.finance.getFinanceStatus(FINANCE_STATUES.Cancelled);
                  state.financeCancelModalRef.open();
                  return;
                }
                break;
              }
              case 1: {
                const { isSign, contentList } = state.orderDetail?.vehicleOrderSignVO ?? {};
                if (isSign === 20 && contentList?.length > 0) {
                  openWindow(contentList[0], '_blank');
                  return;
                } else if ([ORDER_STATUES.Deposit, ORDER_STATUES.DepositLock].includes(orderStatus) && state.orderDetail?.salesOrderInfo?.isCanSignContract) {
                  // login info lotusId(oneId)
                  const oneId = state.isFleet ? state.userInfo?.lotusId : state.orderDetail?.userInfo?.oneId;
                  if (isNullOrEmpty(oneId)) {
                    console.err('there is no oneId responds from order details && login');
                  }
                  const [res, ex] = await api.order.createContract(null, {
                    orderId,
                    oneId,
                    redirectUrl: window.location.href
                  });
                  if (ex) {
                    await toast.showEx(ex);
                    return;
                  }
                  window.location = res?.shortUrl;
                }
                break;
              }
              case 2:
                {
                  if ([ORDER_STATUES.ContractSigned].includes(orderStatus)) {
                    state.paymentModalRef.openFinalPay(state.orderDetail);
                  }
                }
                break;
              default:
                break;
            }
          } else {
            switch (index) {
              case 0: {
                const { isSign, contentList } = state.orderDetail?.vehicleOrderSignVO ?? {};
                if (isSign === 20 && contentList?.length > 0) {
                  openWindow(contentList[0], '_blank');
                  return;
                } else if ([ORDER_STATUES.Deposit, ORDER_STATUES.DepositLock].includes(orderStatus) && state.orderDetail?.salesOrderInfo?.isCanSignContract) {
                  // login info lotusId(oneId)
                  const oneId = state.isFleet ? state.userInfo?.lotusId : state.orderDetail?.userInfo?.oneId;
                  if (isNullOrEmpty(oneId)) {
                    console.err('there is no oneId responds from order details && login');
                  }
                  const [res, ex] = await api.order.createContract(null, {
                    orderId,
                    oneId,
                    redirectUrl: window.location.href
                  });
                  if (ex) {
                    await toast.showEx(ex);
                    return;
                  }
                  window.location = res?.shortUrl;
                }
                break;
              }
              case 1:
                {
                  if ([ORDER_STATUES.ContractSigned].includes(orderStatus)) {
                    state.paymentModalRef.openFinalPay(state.orderDetail);
                  }
                }
                break;
              default:
                break;
            }
          }
        } else {
          window.location = state.orderListInfo.jumpLink;
        }
      },
      changeTab: (i) => {
        state.selectedTab = settingValue(i.fields.type);
      },
      onPay: async () => {
        loading.show();
        await confirmPayment(window.location.href);
        loading.hide();
      },
      showVehicleModal: () => {
        if (state.vehicleStatus) {
          state.modalVehicleRef.open();
        }
      },
      hideVehicleModal: () => {
        state.modalVehicleRef.close();
      },
      async onSubmitFinance() {
        state.financeModalRef.close().catch();
        loading.show();
        if (state.changeFinance) {
          const [verifykey] = getQueryStrings('verifykey');
          const { id } = state.orderDetail.intactOrderDateDto;
          const simulationId = state.simulation?.selectedSimulation?.simulationChecksum?.value ?? state.simulation?.selectedSimulation?.simulationChecksum;
          const [res, ex] = await api.order.intactUpdateMessage(null, { orderId: id, verifykey, simulationId });
          if (ex) {
            loading.hide();
            await toast.showEx(ex);
            return;
          }
          if (res) {
            window.location.reload();
          }
        } else {
          const { orderId } = route.query || {};
          const [res, ex] = await api.finance.apply(null, {
            channel: '1',
            channelCode: state.assetInfo?.channelCode,
            productCode: state.carSeries.financeProduct?.fields.code ?? null,
            orderId,
            redirectionURL: window.location.href,
            simulationId: state.orderDetail.simulationId,
            assetId: state.assetInfo?.assetCode
          });
          if (ex) {
            loading.hide();
            await toast.showEx(ex);
            return;
          }
          if (!res?.url) {
            loading.hide();
            await toast.showEx(null);
            return;
          }
          window.location = res?.url;
        }
      },
      onOpenFinanceCalculator() {
        addModalClass('santander-open');
        global.scfSimulation.configure(
          _methods.buildScfCfg({
            term: state.simulation?.selectedSimulation?.duration.value,
            deposit: state.simulation?.selectedSimulation?.downPayment.value,
            forceSelection: {
              term: state.simulation?.selectedSimulation?.duration.value,
              deposit: state.simulation?.selectedSimulation?.downPayment.value,
              monthlyPayment: state.simulation?.selectedSimulation?.monthlyPayment.value,
              annualMileage: state.simulation?.selectedSimulation?.annualMileage.value
            }
          })
        );
        global.scfSimulation.openSimulator();
      }
    };

    watch(
      () => state.currentStepIndex,
      (v) => {
        if (state.salesOrder) {
          swiper?.slideTo(v + 1, 800);
        }
      }
    );

    onMounted(async () => {
      loading.show();
      state.totalStepCount = 1;
      await nextTick();
      _methods.buildSwiper();
      if (!isNullOrEmpty(verifykey)) {
        state.changeFinance = true;
        [res, ex] = api.order.intactDetailByKey(null, { verifykey });
        if (ex) {
          await toast.showEx(ex);
          setTimeout(() => {
            redirectTo({ url: orderListLink?.value?.href, router });
          }, 3000);
          return;
        }
        const [needRedirect, targetLang] = services.order.checkOrderLang(props.page, res?.salesOrderInfo?.countryCode);
        if (needRedirect) {
          await state.orderLocaleRedirectModalRef.open();
          redirectToLang(targetLang);
        }
        await _methods.setDetail(res);
        await _methods.initFinanceInfo();
      } else {
        if (!orderId) {
          redirectTo({ url: orderListLink?.value?.href, router });
        }
      }
      window.addEventListener('resize', methods.buildSwiper);
    });

    onBeforeUnmount(() => {
      swiper && swiper.destroy(true, true);
      window.removeEventListener('resize', methods.buildSwiper);
      $bus.off(BUS_EVENTS.CONFIRM_FINANCE_CALCULATOR, _methods.onConfirmFinanceCalculator);
    });

    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      FINANCE_STATUES
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-order-details {
  $c: &;
  &__top {
    padding: 0 24px 96px;
    &-model {
      @include h5;
      margin-top: 8px;
    }
    &-id {
      @include h11;
      color: $grey-next;
      margin-top: 8px;
      > span {
        color: #262626;
      }
    }
    &-vehicle {
      margin-top: 8px;
      &-btn {
        width: fit-content;
        display: flex;
        flex-direction: row;
        gap: 4px;
        padding: 2px 12px;
        background-color: $black;
        color: $white;
      }
      &-text {
        margin-top: 8px;
      }
    }
    &-img {
      width: 100%;
      margin: 96px auto 50px;
      overflow: hidden;
      > img {
        width: 100%;
        height: auto;
      }
    }
    &-steps {
      &-nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        margin-bottom: 16px;
        .e-icon {
          cursor: pointer;
          > svg {
            width: 16px;
            height: 16px;
          }
        }
        &-disabled.e-icon {
          color: $grey-taubmans;
          cursor: not-allowed;
        }
      }
      .swiper-wrapper {
        margin-left: 24px;
      }
      &-item {
        width: calc(100vw - 88px);
        &-label {
          @include h11;
          visibility: hidden;
          color: $grey-next;
          &.show {
            visibility: visible;
          }
        }
        &-title {
          font-size: 16px;
          line-height: 24px;
          margin-top: 8px;
          color: $grey-next;
          min-height: 50px;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
        }
        &-line {
          position: relative;
          width: 100%;
          height: 1px;
          background-color: $grey-taubmans;
          margin: 32px 0;
          &.close {
            background-color: $red;
          }
          &.cancel {
            background-color: transparent;
          }
          img {
            width: 32px;
            height: 32px;
          }
          &-icon,
          .e-icon,
          img {
            position: absolute;
            left: 0;
            top: 0;
            transform: translate3d(-50%, -50%, 0);
            &.default {
              width: 8px;
              height: 8px;
              border-radius: 8px;
              border: 1px solid $grey-taubmans;
              background-color: $white;
            }
          }
        }
        &-delivery {
          font-size: 12px;
          line-height: 20px;
          margin-top: -20px;
        }
        &-description {
          @include h11;
          padding-right: 80px;
          color: $grey-dark;
          &.finished {
            color: #e3e3e3;
          }
          &-time {
            font-weight: 700;
          }
        }
        &-btn {
          margin-top: 32px;
        }
        &.current {
          // #{$c}__top-steps-item-label {
          //   visibility: visible;
          // }
          #{$c}__top-steps-item-title {
            color: #262626;
          }
        }
        &.finished {
          #{$c}__top-steps-item-line {
            background-color: $black;
          }
          #{$c}__top-steps-item-description {
            color: #e3e3e3;
          }
        }
      }
    }
  }
  &__bottom {
    padding-top: 96px;
    background-color: $black;
    &-tabs {
      width: 100%;
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      line-height: 32px;
      &-item {
        cursor: pointer;
        border-bottom: 1px solid $white;
        // &:first-child {
        //   padding-right: 24px;
        // }
        // &:last-child {
        //   padding-left: 24px;
        // }
        &.active {
          border-color: $yellow;
          span,
          p,
          div {
            color: $yellow !important;
          }
        }
      }
    }
    &-main {
      padding: 0 24px;

      &-invoices-file {
        > a {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          .e-icon {
            color: #fff200;
          }
        }
      }
      &-car {
        height: 400px;
        width: grid-width(18);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 64px;
        margin: 0 auto;
        &-left {
          flex: 1;
          margin-left: 24px;
          overflow: hidden;
          > img {
            width: 100%;
            height: auto;
          }
        }
        &-right {
          flex: 1;
          color: $white;
          &-model {
            font-size: 32px;
            line-height: 32px;
          }
          &-num {
            margin-top: 16px;
            font-size: 18px;
            line-height: 32px;
          }
          &-text {
            font-size: 16px;
            line-height: 24px;
            color: $grey-taubmans;
          }
        }
      }
      &-item {
        width: 100%;
        margin: 0 auto;
        padding: 64px 0;
        border-bottom: 1px solid #2f2f2f;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        &:last-child {
          border: none;
        }
        &-label {
          font-size: 32px;
          line-height: 32px;
          flex: 1;
        }
        &-list {
          width: 100%;
          flex: 2;
          display: flex;
          flex-direction: column;
          &-item {
            line-height: 24px;
            &:nth-child(2n + 3),
            &:nth-child(2n + 4) {
              margin-top: 24px;
            }
            &-label {
              color: $white;
              font-size: 14px;
            }
            &-text {
              display: flex;
              flex-direction: column;
              gap: 4px;
              color: $grey-taubmans;
              font-size: 16px;
              margin-top: 8px;
            }
          }
          &.finance {
            gap: 24px;
            #{$c}__bottom-main-price {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              font-size: 16px;
              line-height: 24px;
              color: $grey-taubmans;
            }
          }
        }
      }
      &-price {
        width: 100%;
        line-height: 24px;
        & + & {
          #{$c}__bottom-main-price-subtitle {
            margin-top: 32px;
          }
        }
        &-subtitle {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 14px;
        }
        &-value {
          font-size: 12px;
          color: $grey-next;
          margin-top: 16px;
        }
        &-item {
          font-size: 16px;
          color: $grey-taubmans;
          &.e-label-value {
            padding: 0;
          }
        }
      }
      &-total {
        width: 100%;
        margin-top: 64px;
        .e-label-value {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 32px;
        }
        &-top {
          color: $grey-taubmans;
          font-size: 16px;
          line-height: 24px;
          border-bottom: 1px solid #2f2f2f;
          margin-bottom: 32px;
          &.e-label-value {
            padding-bottom: 24px;
          }
        }
        &-bottom {
          font-size: 20px;
          line-height: 28px;
          font-weight: 500;
          border-top: 1px solid #2f2f2f;
        }
      }
      &-configuration {
        margin-top: 64px;
        .e-label-value {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $grey-taubmans;
        }
        > .e-label-value {
          margin: 16px 0;
        }
        &-title {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          text-transform: uppercase;
        }
        &-block {
          padding: 24px;
          border: 1px solid $grey-dark;
          .e-label-value {
            padding: 0;
            margin-bottom: 16px;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
  &__vehicle-modal {
    .e-modal__content {
      width: 100vw;
      padding: 40px 0 32px;
    }
    &-text {
      padding: 0 24px;
    }
    &-eyebrow {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $black;
    }
    &-title {
      margin-top: 8px;
      font-weight: 400;
      font-size: 32px;
      line-height: 32px;
      color: $black;
    }
    &-media {
      margin-top: 48px;
      .e-adaptive-media__image {
        display: flex;
        > img {
          width: 100%;
          height: auto;
        }
      }
      .e-video-player .video-js {
        padding: 0;
        .vjs-tech {
          position: unset;
        }
        video {
          height: auto;
        }
      }
    }
    &-body {
      margin-top: 48px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $grey-next;
    }
    &-time {
      margin-top: 24px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $black;
    }
    &-location {
      margin-top: 24px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $black;
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &-btn {
      height: 56px;
      width: calc(100% - 48px);
      margin-top: 40px;
      margin-left: 24px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $black;
    }
  }
  &__finance-modal {
    .e-modal__content {
      width: 800px;
    }
    &-title {
      margin: 96px 64px 48px;
    }
    &-body {
      margin: 0 64px 56px;
    }
    &-btn {
      margin: 0 64px 64px;
      .e-site-button {
        width: 100%;
      }
    }
  }
  &__finance-close-modal {
    .e-modal__content {
      padding: 80px;
      width: 600px;
      height: 600px;
    }
    &-title {
      margin-bottom: 32px;
    }
    &-subtitle {
      margin-bottom: 16px;
    }
  }
  @include tablet-landscape {
    &__top {
      padding: 24px 108px 140px;
      &-model {
        margin-top: 20px;
      }
      &-id {
        margin-top: 16px;
      }
      &-vehicle {
        margin-top: 16px;
        &-btn {
          cursor: pointer;
        }
        &-text {
          width: grid-width(3);
          margin-top: 16px;
          word-break: break-word;
        }
      }
      &-img {
        width: grid-width(15);
        margin: 68px auto 36px;
      }
      &-steps {
        &-nav {
          margin-left: 50px;
          margin-right: 50px;
        }
        &-item {
          width: grid-width(6);
        }
      }
    }
    &__bottom {
      padding-top: 120px;
      &-main {
        padding: 0 210px;
        &-item {
          padding: 96px 0;
          flex-direction: row;
          &-list {
            width: auto;
            flex-direction: row;
            flex-wrap: wrap;
            &-item {
              width: 50%;
              &:nth-child(2n + 3),
              &:nth-child(2n + 4) {
                margin-top: 48px;
              }
            }
            &-invoices + &-invoices {
              #{$c}__bottom-main-item-list-item-label {
                visibility: hidden;
              }
            }
          }
        }
        &-price {
          & + & {
            #{$c}__bottom-main-price-subtitle {
              margin-top: 48px;
            }
          }
        }
        &-total {
          margin-top: 96px;
          &-bottom {
            font-size: 24px;
            line-height: 32px;
          }
        }
        &-configuration {
          margin-top: 96px;
          width: 100%;
        }
      }
    }
    &__vehicle-modal {
      .e-modal__content {
        width: 800px;
        padding: 96px 0 80px;
      }
      &-text {
        padding: 0 64px;
      }
      &-btn {
        width: calc(100% - 128px);
        margin-left: 64px;
      }
    }
  }
}
html.rtl {
  .c-order-details__top-steps-nav {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
</style>
