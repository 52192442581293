<script setup>
import { computed, inject, nextTick, onMounted, provide, reactive } from 'vue';
import { isBool, isNullOrEmpty } from '@/utils/obj-utils';
import { getPageAlpha2Code, settingValue } from '@/utils/site-utils';
import { sitecoreComponentProps } from '@/utils/constants';
import { getQueryStrings } from '@/utils/uri-utils';
import utils from '@/utils';
import { debounce, merge } from 'lodash';
import { canUseDOM, sleep, tryParseJson } from '@/utils/dom-utils';
import { formatDate } from '@/utils/date-utils';
import services from '@/services';
import api from '@/api';
import { equalString } from '@/utils/string-utils';
import { analyzeVehicleFeatures, buildModelImgUrl, getFeaturePriceIds } from '@/utils/mhp-utils';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';
import useAppStore from '@/store/appStore';

const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
const loading = inject('loading');
const toast = inject('toast');
/**@type AppStore*/
const appStore = useAppStore();
const state = reactive({
  /**@type DynamicForm*/
  legalFormRef: null,
  /**@type {?LightFormRef}*/
  lightFormsRef: null,
  /**@type {?OneFormSwiperRef}*/
  swiperEl: null,
  firstDetailEl: null,
  /**@type {?CalculatorContainerRef}*/
  calculatorContainerRef: null,
  turn: 0,
  alpha2Code: getPageAlpha2Code(props.page),
  filterId: null,
  transferCode: null,
  configurationCode: null,
  vin: null,
  /**@type {?UsedCarQuery}*/
  usedCarQuery: null,
  formulaNo: null,
  paymentFormulaNo: null,
  sectionInstances: {},
  selectedVehicles: [],
  dealer: null,
  lockDealer: false,
  customerType: null,
  /**@type {?String}*/
  selectedDate: null,
  /**@type {?String}*/
  selectedTime: null,
  /**@type {Array<PaymentOption>}*/
  paymentOptions: [],
  /**@type {?PaymentOption}*/
  paymentOption: null,
  /**@type {?CarSeries}*/
  carSeries: null,
  /**@type {?CarModel}*/
  carModel: null,
  carImages: [],
  configuration: null,
  currency: null,
  buttonFeatureTypes: [],
  features: [],
  accessories: [],
  priceIds: [],
  accPriceIds: [],
  /**@type {?number}*/
  retailPrice: null,
  /**@type {?number}*/
  basePrice: null,
  /**@type {?number}*/
  vatAmount: null,
  hasFinanceLevel2: false,
  detailFormValid: false,
  detailFormData: null,
  lightFormsValid: false,
  lightFormsData: null,
  legalFormValid: false,
  legalFormData: null,
  submitted: false
});
const calculation = reactive({
  /**@type {SantanderQuote}*/
  santander: null,
  /**@type {CwFinanceQuote}*/
  codeWaver: null
});
const computes = {
  dockCode: computed(() => state.paymentOption?.dock.code),
  formTypeVal: computed(() => settingValue(props.fields?.formType))
};
const sections = computed(() => {
  return (
    props.fields?.sections.map((x) => ({
      id: x.id,
      fields: utils.oneForm.buildInheritFields(x.fields)
    })) ?? []
  );
});
const lightFormFields = computed(() => utils.oneForm.buildInheritFields(props.fields?.lightForms?.fields));
const legalFields = computed(() => utils.oneForm.buildInheritFields(props.fields?.legalBlock?.fields));
const isSubmitDisabled = computed(() => {
  if (!state.legalFormValid) return true;
  for (let section of sections.value) {
    const sectionType = settingValue(section.fields.sectionType);
    console.log('sectionType', sectionType);
    if (sectionType === 'vehicle' && state.selectedVehicles.length === 0) {
      return true;
    }
    if (sectionType === 'location' && isNullOrEmpty(state.dealer)) {
      return true;
    }
  }
  return false;
});
const methods = {
  getSectionType(section) {
    return settingValue(section.fields.sectionType).replace(/(-\w)/gi, (match) => match.slice(1).toUpperCase());
  },
  expandNext(index) {
    if (!sections.value?.length) return;
    if (index === sections.value.length - 1) {
      return;
    }
    let nextSection = null;
    for (let i = index + 1; i < sections.value?.length; i++) {
      const nextSecFields = sections.value[i].fields;
      const nextSecType = settingValue(nextSecFields.sectionType).replace(/(-\w)/gi, (match) => match.slice(1).toUpperCase());
      const nextSec = state.sectionInstances[nextSecType];
      if (!nextSec?.state.readonly.value && !nextSec?.state.invisible.value) {
        nextSection = nextSec;
        break;
      }
    }
    if (nextSection !== null) {
      nextSection.expand();
    }
  },
  editFinance() {
    state.calculatorContainerRef.open();
  },
  async initFinanceInfo() {
    if (!state.hasFinanceLevel2) return;
    if (!isNullOrEmpty(state.usedCarDetail) || !state.carSeries?.usedCarL2) return;
    else if (!state.carSeries?.financeLevel2) return;
    state.calculatorContainerRef.init({
      configuration: state.configuration,
      features: state.features,
      carImage: state.carImages?.length > 0 ? state.carImages[0] : '',
      customerType: { code: 'personal' },
      paymentOption: state.paymentOption,
      retailPrice: state.retailPrice,
      basePrice: state.basePrice,
      vatAmount: state.vatAmount,
      currency: state.currency,
      preSelection: state.financeData,
      vehicleType: isNullOrEmpty(state.usedCarDetail) ? 'New' : 'Used',
      registrationTime: state.usedCarDetail?.basicResp?.registrationTime
    });
    state.financeInitialized = true;
  }
};
const setSectionRef = (index, section, e) => {
  const total = sections.value?.length;
  const sectionType = methods.getSectionType(section);
  const sectionInstance = e ?? {};
  if (index < total - 1) {
    const nextIndex = index + 1;
    const nextSection = sections.value[nextIndex];
    sectionInstance.nextSecType = methods.getSectionType(nextSection);
    sectionInstance.nextSecIndex = nextIndex;
  }
  state.sectionInstances[sectionType] = sectionInstance;
};
const onLegalFormChange = async (valid, data) => {
  console.log('legal valid', valid, data);
  [state.legalFormValid, state.legalFormData] = [valid, data];
};
const isFirstSectionCollapsed = () => {
  if (!sections.value?.length) return;
  const firstSecType = methods.getSectionType(sections.value[0]);
  return state.sectionInstances[firstSecType]?.state.collapsed.value;
};
const onSectionChange = (section, args) => {
  console.log('onSectionChange', section, args);
};
const parseHiddenData = (data) => {
  return utils.sitecore.parseNVL(data, (k, v) => {
    if (['true', 'false'].some((x) => equalString(x, v))) return v === 'true';
    return v;
  });
};
const buildPayOptions = () => {
  if (isNullOrEmpty(state.usedCarDetail)) {
    if (state.carSeries?.financeLevel2) {
      state.paymentOptions = unWrapSitecoreItem(state.carSeries?.level2PaymentOptions).filter((x) => !equalString(x.name, 'cash')) ?? [];
    } else {
      state.paymentOptions = [];
      state.financeCalculating = false;
    }
  } else {
    if (state.carSeries?.usedCarL2) {
      state.paymentOptions = unWrapSitecoreItem(state.carSeries?.usedCarL2PayOptions).filter((x) => !equalString(x.name, 'cash')) ?? [];
    } else {
      state.paymentOptions = [];
      state.financeCalculating = false;
    }
  }
  if (state.paymentOptions.length > 0) {
    state.paymentOption = state.paymentOptions[0];
  }
};
const analyzeCfg = async (res, isStock = false) => {
  state.configuration = res;
  [state.features, state.accessories] = analyzeVehicleFeatures(res, isStock);
  [state.formulaNo, state.paymentFormulaNo] = await services.site.getFormulaNosByCode(props.page, res.seriesCode);
  state.priceIds = getFeaturePriceIds(state.features);
  state.accPriceIds = utils.mhp.getAccessoryPriceIds(state.accessories);
  state.carSeries = await services.site.getCarSeriesByCode(res.seriesCode);
  state.carModel = await services.site.getCarModelByCode(res.seriesCode, res.modelCode);
  const buttonFeatureTypes = utils.mhp.getButtonFeatureTypes(state.features, state.accessories);
  console.log('state.carSeries', state.carSeries);
  if (!isNullOrEmpty(state.carModel?.packageCode)) {
    buttonFeatureTypes.push(state.carModel.packageCode);
  }
  state.buttonFeatureTypes = buttonFeatureTypes;
  buildPayOptions();
};
const loadUsedCarDetail = async () => {
  const [res, ex] = await api.usedCar.detail(null, {
    id: state.usedCarQuery.id,
    longitude: appStore.geoLocation?.longitude ?? null,
    latitude: appStore.geoLocation?.latitude ?? null
  });
  state.usedCarDetail = res;
  state.hasFinanceLevel2 = !!res;
  const { basicResp, picList, priceResp } = res ?? {};
  const { vehicleConfigInfo, vin, series, model, saleStore } = basicResp ?? {};
  const { allInPrice, currency } = priceResp ?? {};
  state.retailPrice = allInPrice;
  state.basePrice = allInPrice;
  state.carSeries = await services.site.getCarSeries(series);
  state.carModel = await services.site.getCarModel(series, model);
  state.currency = currency;
  if (!isNullOrEmpty(saleStore)) {
    [state.dealer] = await api.store.detail({
      storeCode: saleStore
    });
  }
  const configuration = {
    filterId: state.carModel?.filterId,
    filterName: model,
    seriesCode: state.carSeries?.code,
    modelCode: state.carModel?.code,
    series: series,
    model: model,
    currency: currency,
    vin: vin,
    children: utils.dom.tryParseJson(vehicleConfigInfo, [])
  };
  if (picList?.length > 0) {
    state.image = picList[0]?.url;
    state.carImages = picList.map((x) => x.url);
  }
  state.configuration = configuration;
  state.lockDealer = true;
  buildPayOptions();
};
const loadStockDetail = async () => {
  const [res, ex] = await api.order.stockDetailByVin(null, {
    country: state.alpha2Code,
    vin: state.vin
  });
  if (!res?.configurationDetails || !res?.detailPrice) {
    await toast.showEx(ex);
    return;
  }
  state.hasFinanceLevel2 = true;
  state.transferCode = res.transferCode;
  state.filterId = res.filterId;
  state.vid = res.vid;
  const configuration = {
    filterName: res.filterName,
    seriesCode: res.seriesCode,
    modelCode: res.modelCode,
    series: res.series,
    model: res.vehicleModel,
    organization: res.organization,
    currency: res.currency,
    nextLevels: utils.dom.tryParseJson(res.configurationDetails, [])
  };
  state.currency = res.currency;
  state.priceInfo = tryParseJson(res.detailPrice, []);
  await analyzeCfg(configuration, true);
  state.retailPrice = utils.site.getPriceFromPcData(state.priceInfo, 'Vehicle Total Price', 'Total Price');
  state.basePrice = utils.site.getPriceFromPcData(state.priceInfo, 'Base Retail Price', 'Base Price');
  state.vatAmount = utils.site.getPriceFromPcData(state.priceInfo, 'Incl. VAT');
  if (res?.images?.productDisplay?.length > 0) {
    state.image = res?.images?.productDisplay[0]?.externalUrl;
    state.carImages = res?.images?.productDisplay?.map((x) => x.externalUrl) ?? [];
  }
  if (res?.organization === 2) {
    state.storeCode = res.storeCode;
  }
  if (res?.organization === 2) {
    state.lockDealer = true;
  }
};
const loadConfiguration = async () => {
  const [res, ex] = await api.configuration.get(null, {
    filterId: state.filterId,
    transferCode: state.transferCode,
    engineeringCode: 1,
    showPrice: 1
  });
  if (!res) {
    await toast.showEx(ex);
    loading.hide();
    return;
  }
  state.configuration = res;
  state.currency = res.currency;
  await analyzeCfg(res, false);
  await loadPrice();
  state.retailPrice = utils.site.getPriceFromPcData(state.priceInfo, 'Vehicle Total Price', 'Total Price');
  state.basePrice = utils.site.getPriceFromPcData(state.priceInfo, 'Base Retail Price', 'Base Price');
  state.vatAmount = utils.site.getPriceFromPcData(state.priceInfo, 'Incl. VAT');
  state.carImages = await services.mhp.getImages(props.page, state.configuration.series, state.carModel, state.filterId, state.buttonFeatureTypes);
  state.image = buildModelImgUrl(props.page, state.filterId, res.series, state.buttonFeatureTypes, state.carModel, 'Mountain', {
    camera: {
      id: 'BS_Config_Ext_Front_34_measurements',
      options: []
    }
  });
};
const loadPrice = async () => {
  state.priceInfo = await services.price.getCarPrices(state.filterId, state.transferCode, state.formulaNo, state.alpha2Code, state.configuration?.modelCode, state.priceIds, [], state.accPriceIds);
  state.retailPrice = utils.site.getPriceFromPcData(state.priceInfo, 'Vehicle Total Price', 'Total Price');
  state.basePrice = utils.site.getPriceFromPcData(state.priceInfo, 'Base Retail Price', 'Base Price');
  state.vatAmount = utils.site.getPriceFromPcData(state.priceInfo, 'Incl. VAT');
};
const onFinanceCalculated = debounce(async (dock, payload) => {
  console.log('calculated', dock, payload);
  calculation[dock] = null;
  await nextTick();
  calculation[dock] = payload;
  state.financeCalculating = false;
}, 200);
const onSubmit = debounce(async () => {
  const needDoubleOptin = await services.site.countryNeedDoubleOptin(state.alpha2Code);
  const body = {};
  if (state.sectionInstances.detailForm) {
    [state.detailFormValid, state.detailFormData] = await state.sectionInstances.detailForm.validate();
  }
  [state.lightFormsValid, state.lightFormsData] = await state.lightFormsRef.validate();
  if (state.detailFormValid && state.lightFormsValid && state.legalFormValid) {
    loading.show();
    const hiddenData = Object.create(null);
    const { globalHiddenData, localHiddenData } = props.fields;
    if (!isNullOrEmpty(globalHiddenData?.value)) {
      const globalHiddenDataVal = parseHiddenData(globalHiddenData?.value);
      merge(hiddenData, globalHiddenDataVal);
    }
    if (!isNullOrEmpty(localHiddenData?.value)) {
      const localHiddenDataVal = parseHiddenData(localHiddenData?.value);
      merge(hiddenData, localHiddenDataVal);
    }
    const consentSet = new Set([...state.lightFormsRef.getVerifiedConsents(), ...state.legalFormRef.getVerifiedConsents()]);
    const consents = Array.from(consentSet);
    let testDriveRequest = false;
    if (computes.formTypeVal.value === 'test-drive') {
      testDriveRequest = true;
    }
    if (isBool(state.legalFormData?.testDriveRequest)) {
      testDriveRequest = state.legalFormData.testDriveRequest;
    }
    const body = {
      countryRegion: state.alpha2Code,
      language: props.page.itemLanguage,
      needDoubleOptin,
      termsAndConditions: consents.map((x) => ({
        revisionNumber: x,
        title: window.document.title,
        formUrl: window.location.href,
        effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
      })),
      vehicleModelOfInterest: state.selectedVehicles.map((x) => x.vehicleName).join(','),
      testDriveRequest,
      storeCode: state.dealer?.storeCode
    };
    const detailBody = utils.site.formData2Body(state.detailFormData);
    merge(body, detailBody);
    if (!isNullOrEmpty(hiddenData)) {
      merge(body, hiddenData);
    }
    console.log('body', body);
    const [res, ex] = await api.oneForm.createLead(null, body).finally(() => {
      loading.hide();
    });
    if (ex) {
      await toast.showEx(ex);
      return;
    }
    if (state.lightFormsData?.CPTC) {
      const cptcBody = merge(
        {
          firstName: state.detailFormData.firstName,
          middleName: state.detailFormData.middleName ?? '',
          lastName: state.detailFormData.lastName,
          email: state.detailFormData.email,
          countryRegion: state.alpha2Code,
          channel: 'Official Website'
        },
        state.detailFormData?.externalData
      );
      const isAboveTwenty = state.lightFormsData?.isAboveTwenty ?? state.legalFormData?.isAboveTwenty;
      if (!isNullOrEmpty(isAboveTwenty)) {
        merge(cptcBody, { isAboveTwenty });
      }
      if (!isNullOrEmpty(hiddenData)) {
        merge(body, hiddenData);
      }
      await api.lcms.crm.cptc.create(null, cptcBody);
    }
    state.submitted = true;
    await nextTick();
    utils.site.scrollToTop();
  }
}, 50);
provide('one-form-methods', methods);
provide('one-form-state', state);
provide('one-form-computes', computes);
provide('one-form-calculation', calculation);
if (canUseDOM()) {
  loading.show();
}
onMounted(async () => {
  loading.show();
  let finance = '';
  [state.filterId, state.transferCode, state.configurationCode, state.vin, state.storeCode, finance] = getQueryStrings(
    'filter_id',
    'transfer_code',
    'configuration_code',
    'vin',
    'store_code',
    'finance'
  );
  [state.usedCarQuery] = utils.uri.getQueryDataList('used_car');
  state.alpha2Code = getPageAlpha2Code(props.page);
  if (!isNullOrEmpty(state.usedCarQuery)) {
    await loadUsedCarDetail();
  } else if (!isNullOrEmpty(state.vin)) {
    await loadStockDetail();
  } else if (!isNullOrEmpty(state.transferCode) && !isNullOrEmpty(state.filterId)) {
    await loadConfiguration();
  }
  state.hasFinanceLevel2 = !isNullOrEmpty(state.filterId) && !isNullOrEmpty(state.transferCode);
  if (!isNullOrEmpty(finance)) {
    state.financeData = utils.site.decodeData(finance);
    if (!isNullOrEmpty(state.financeData)) {
      state.lightFormsRef.updateFormItems({
        financeInterest: {
          controlProps: {
            value: true
          }
        }
      });
      await nextTick();
      await methods.initFinanceInfo();
    }
  }
  await nextTick();
  await state.swiperEl?.init(state.carImages);
  await state.sectionInstances.vehicle?.init();
  await state.sectionInstances.location?.init(state.storeCode);
  state.selectedDate = utils.date.formatDate(new Date());
  state.selectedTime = '09:00';
  await sleep(100);
  for (let section of sections.value) {
    const secType = methods.getSectionType(section);
    const secRef = state.sectionInstances[secType];
    if (secRef.state.invisible.value || secRef.state.preSelected?.value) continue;
    if (isFirstSectionCollapsed()) {
      secRef.expand();
    }
    break;
  }
  loading.hide();
});
</script>

<template>
  <div class="c-one-form" :class="[computes.formTypeVal.value]" v-if="!isNullOrEmpty(computes.formTypeVal.value)">
    <div class="c-one-form__main" v-if="!state.submitted">
      <one-form-intro v-bind="fields.introBlock">
        <one-form-swiper :images="state.carImages" :ref="(e) => (state.swiperEl = e)" />
        <div class="c-one-form__sections">
          <template v-for="(section, i) in sections" :key="section.id">
            <component
              :is="`one-form-${$settingValue(section.fields.sectionType)}`"
              v-bind="section"
              :index="i"
              :total="sections.length"
              @change="(...args) => onSectionChange(section, args)"
              :ref="(e) => setSectionRef(i, section, e)"
            />
          </template>
        </div>
        <one-form-light-forms :fields="lightFormFields" :ref="(e) => (state.lightFormsRef = e)" />
        <one-form-legal :fields="legalFields" @change="onLegalFormChange" :ref="(e) => (state.legalFormRef = e)" />
        <div class="c-one-form__buttons">
          <site-button v-bind="fields.submitButton" @click="onSubmit" :disabled="isSubmitDisabled" />
        </div>
        <one-form-contact v-bind="fields.contactBlock" />
      </one-form-intro>
    </div>
    <one-form-success :fields="fields" v-else />
    <calculator-container :ref="(e) => (state.calculatorContainerRef = e)" @calculated="onFinanceCalculated" v-model:calculating="state.financeCalculating" />
  </div>
</template>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-one-form {
  $img: '.e-background-image';
  &__main {
    background: $black;
  }
  &__buttons {
    padding: 24px grid-width-m(1) 80px grid-width-m(1);
    .e-site-button {
      width: 100% !important;
    }
  }
  @include tablet-landscape {
    &__main {
      padding-top: 80px;
    }
    &__buttons {
      padding: 24px grid-width(1) 80px grid-width(1);
    }
  }
}
</style>
