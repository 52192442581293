<template>
  <DashboardLayout :fields="fields?.dashboardHeader?.fields" active-menu="order-list">
    <OverallSettings v-bind="$props">
      <div class="c-order-list" v-if="fields">
        <div class="c-order-list__title" v-if="!tabList?.length && appStore?.orderInfo?.length">
          <JssRichText :field="largeThanTablet() ? fields.titlePC : fields.titleMobile" />
        </div>
        <div
          class="c-order-list__tab"
          v-if="(appStore?.orderInfo?.length || appStore?.shopOrderInfo?.length || appStore?.serviceOrderInfo?.length || appStore?.partsOrderInfo?.length) && tabList?.length"
        >
          <div class="c-order-list__tab__content">
            <div class="c-order-list__tab__item" v-for="(item, index) in tabList" :key="item.id" :class="index === tabCurrentIndex ? 'active' : null">
              <JssRichText :field="item.fields.tabTitle" @click="changeTab(index)" />
            </div>
          </div>
        </div>
        <div class="c-order-list__title" v-if="(appStore?.orderInfo?.length || appStore?.shopOrderInfo?.length) && tabList?.length">
          <JssRichText :field="tabList[tabCurrentIndex]?.fields?.title" />
        </div>
        <!-- vehicle -->
        <div class="c-order-list__vehicle" v-if="tabList?.length && tabList[tabCurrentIndex]?.fields?.tabCode?.value === 'vehicle'">
          <div class="c-order-list__main">
            <div class="c-order-list__main-order" v-if="mainOrder">
              <img class="c-order-list__main-order-image" :src="mainOrder.modelImage" :alt="mainOrder.model" v-if="mainOrder.modelImage" />
              <div class="c-order-list__main-order-details">
                <div class="c-order-list__main-order-details-title">
                  <div class="c-order-list__main-order-details-status">{{ mainOrder.status?.name ?? '' }}</div>
                  <div
                    class="c-order-list__main-order-details-type"
                    v-html="ifBto(mainOrder, $equalString(mainOrder.paymentSchemeCode, '200') ? $tu('Loan order') : $tu('Cash order'), $tu('Reservation order'))"
                    v-if="!$equalString(mainOrder.orderType, '800')"
                  />
                </div>
                <div class="c-order-list__main-order-details-model">{{ mainOrder.series }}</div>
                <div class="c-order-list__main-order-details-body">
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-create" v-if="mainOrder.createTime">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Ordered') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">{{ mainOrder.createTime }}</div>
                  </div>
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-update">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Last update') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">{{ mainOrder.paymentTime ? mainOrder.paymentTime : mainOrder.createTime }}</div>
                  </div>
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-id" v-if="mainOrder.orderId">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Order No') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">{{ mainOrder.orderId }}</div>
                  </div>
                </div>
                <div class="c-order-list__main-order-details-soldout" v-if="mainOrder.soldOut">
                  <JssRichText :field="fields.soldOutDescription" />
                </div>
                <div class="c-order-list__main-order-details-btn">
                  <SiteButton :fields="merge({}, mainOrderButton, getOrderLink(mainOrder))" v-if="getOrderLink(mainOrder)" />
                </div>
              </div>
            </div>
            <div class="c-order-list__main-list" v-if="listOrders?.length">
              <div class="c-order-list__main-list-item" :class="{ clickable: order.jumpLink }" v-for="order in listOrders" :key="order.id" @click="goDetails(order)">
                <img class="c-order-list__main-list-item-image" :src="order.modelImage" :alt="order.model" v-if="order.modelImage" />
                <BackgroundImage
                  v-if="order?.items?.length > 0 && order?.items[0]?.image"
                  class="c-order-list__main-list-item-background-image"
                  :image="{
                    value: {
                      src: order.items[0].image
                    }
                  }"
                />
                <div class="c-order-list__main-list-item-details">
                  <div class="c-order-list__main-list-item-details-title">
                    <div class="c-order-list__main-list-item-details-status">{{ order.status?.name ?? '' }}</div>
                    <div
                      v-if="order.paymentSchemeCode"
                      class="c-order-list__main-list-item-details-type"
                      v-html="ifBto(order, $equalString(order.paymentSchemeCode, '200') ? $tu('Loan order') : $tu('Cash order'), $tu('Reservation order'))"
                    />
                    <div v-if="order.spuPayType" class="c-order-list__main-list-item-details-type" v-html="order.spuPayType == 51071001 ? $tu('Cash order') : null" />
                    <!-- <div class="c-order-list__main-list-item-details-info" v-html="order.orderType === '100' ? $t('Convert to sales order') : order.status?.nextStepDescription" /> -->
                  </div>
                  <div class="c-order-list__main-list-item-details-model">{{ order.series }}</div>
                  <div class="c-order-list__main-list-item-details-body">
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-create">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Ordered') }}:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">{{ order.createTime }}</div>
                    </div>
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-update">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Last update') }}:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">{{ order.paymentTime ? order.paymentTime : order.createTime }}</div>
                    </div>
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-id">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Order No') }}.:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">{{ order.orderId }}</div>
                    </div>
                  </div>
                </div>
                <div class="c-order-list__main-list-item-icon" v-if="order.jumpLink && $deviceComputes.largeThanTablet.value">
                  <Icon name="arrow-right" />
                </div>
              </div>
            </div>
          </div>
          <div class="c-order-list__empty" v-if="!appStore?.orderInfo?.length">
            <JssRichText class="c-order-list__empty-title" :field="tabList[tabCurrentIndex]?.fields?.emptyTitle" />
            <JssRichText class="c-order-list__empty-body" :field="tabList[tabCurrentIndex]?.fields?.emptyDescription" />
            <SiteButton class="c-order-list__empty-btn" v-bind="fields.emptyButton" />
          </div>
        </div>
        <!-- parts -->
        <div class="c-order-list__vehicle" v-if="tabList?.length && tabList[tabCurrentIndex]?.fields?.tabCode?.value === 'parts'">
          <div class="c-order-list__main">
            <div class="c-order-list__main-order active" v-if="partsMainOrder">
              <div class="c-order-list__main-order-imagecon">
                <img class="c-order-list__main-order-imagecon-image active" :src="partsMainOrder.items[0]?.image" :alt="partsMainOrder.items[0]?.name" v-if="partsMainOrder.items[0]?.image" />
              </div>
              <div class="c-order-list__main-order-details">
                <div class="c-order-list__main-order-details-title">
                  <div class="c-order-list__main-order-details-status">{{ partsMainOrder.status?.name ?? '' }}</div>
                </div>
                <div class="c-order-list__main-order-details-model">{{ partsMainOrder.series }}</div>
                <div class="c-order-list__main-order-details-body">
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-create" v-if="partsMainOrder.createTime">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Ordered') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">{{ $formatDate(partsMainOrder.createTime, 'MM-dd-yyyy') }}</div>
                  </div>
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-update">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Last update') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">
                      {{ partsMainOrder.paymentTime ? $formatDate(partsMainOrder.paymentTime, 'MM-dd-yyyy') : $formatDate(partsMainOrder.createTime, 'MM-dd-yyyy') }}
                    </div>
                  </div>
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-id" v-if="partsMainOrder.orderId">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Order No') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">{{ partsMainOrder.orderId }}</div>
                  </div>
                </div>
                <div class="c-order-list__main-order-details-soldout" v-if="partsMainOrder.soldOut">
                  <JssRichText :field="fields.soldOutDescription" />
                </div>
                <div class="c-order-list__main-order-details-btn">
                  <SiteButton v-bind="fields.winterWheelsOrderDetailButton" @click="goDetails(partsMainOrder)" />
                </div>
              </div>
            </div>
            <div class="c-order-list__main-list" v-if="partsListOrders?.length">
              <div class="c-order-list__main-list-item" :class="{ clickable: order.jumpLink }" v-for="order in partsListOrders" :key="order.id" @click="goDetails(order)">
                <div class="c-order-list__main-list-item-imagecon">
                  <img class="c-order-list__main-list-item-imagecon-image" :src="order.items[0].image" :alt="order.items[0].name" v-if="order.items[0].image" />
                </div>
                <div class="c-order-list__main-list-item-details">
                  <div class="c-order-list__main-list-item-details-title">
                    <div class="c-order-list__main-list-item-details-status">{{ order.status?.name ?? '' }}</div>
                  </div>
                  <div class="c-order-list__main-list-item-details-model">{{ order.series }}</div>
                  <div class="c-order-list__main-list-item-details-body">
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-create">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Ordered') }}:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">{{ $formatDate(order.createTime, 'MM-dd-yyyy') }}</div>
                    </div>
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-update">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Last update') }}:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">
                        {{ order.paymentTime ? $formatDate(order.paymentTime, 'MM-dd-yyyy') : $formatDate(order.createTime, 'MM-dd-yyyy') }}
                      </div>
                    </div>
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-id">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Order No') }}.:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">{{ order.orderId }}</div>
                    </div>
                  </div>
                </div>
                <div class="c-order-list__main-list-item-icon" v-if="order.jumpLink && $deviceComputes.largeThanTablet.value">
                  <Icon name="arrow-right" />
                </div>
              </div>
            </div>
          </div>
          <div class="c-order-list__empty" v-if="!appStore?.partsOrderInfo?.length">
            <JssRichText class="c-order-list__empty-title" :field="tabList[tabCurrentIndex]?.fields?.emptyTitle" />
            <JssRichText class="c-order-list__empty-body" :field="tabList[tabCurrentIndex]?.fields?.emptyDescription" />
            <SiteButton class="c-order-list__empty-btn" v-bind="fields.emptyButton" />
          </div>
        </div>
        <!-- service -->
        <div class="c-order-list__vehicle" v-if="tabList?.length && tabList[tabCurrentIndex]?.fields?.tabCode?.value === 'service'">
          <div class="c-order-list__main">
            <div class="c-order-list__main-order" v-if="serviceMainOrder">
              <img class="c-order-list__main-order-image" :src="serviceMainOrder.items[0]?.image" :alt="serviceMainOrder.items[0]?.name" v-if="serviceMainOrder.items[0]?.image" />
              <div class="c-order-list__main-order-details">
                <div class="c-order-list__main-order-details-title">
                  <div class="c-order-list__main-order-details-status">{{ serviceMainOrder.status?.name ?? '' }}</div>
                </div>
                <div class="c-order-list__main-order-details-model">{{ serviceMainOrder.series }}</div>
                <div class="c-order-list__main-order-details-body">
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-create" v-if="serviceMainOrder.createTime">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Ordered') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">{{ $formatDate(serviceMainOrder.createTime, 'MM-dd-yyyy') }}</div>
                  </div>
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-update">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Last update') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">
                      {{ serviceMainOrder.paymentTime ? $formatDate(serviceMainOrder.paymentTime, 'MM-dd-yyyy') : $formatDate(serviceMainOrder.createTime, 'MM-dd-yyyy') }}
                    </div>
                  </div>
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-id" v-if="serviceMainOrder.orderId">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Order No') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">{{ serviceMainOrder.orderId }}</div>
                  </div>
                </div>
                <div class="c-order-list__main-order-details-soldout" v-if="serviceMainOrder.soldOut">
                  <JssRichText :field="fields.soldOutDescription" />
                </div>
                <div class="c-order-list__main-order-details-btn">
                  <SiteButton v-bind="fields.serviceOrderDetailButton" @click="goDetails(serviceMainOrder)" />
                </div>
              </div>
            </div>
            <div class="c-order-list__main-list" v-if="serviceListOrders?.length">
              <div class="c-order-list__main-list-item" :class="{ clickable: order.jumpLink }" v-for="order in serviceListOrders" :key="order.id" @click="goDetails(order)">
                <img class="c-order-list__main-list-item-image" :src="order.modelImage" :alt="order.model" v-if="order.modelImage" />
                <BackgroundImage
                  v-if="order?.items?.length > 0 && order?.items[0]?.image"
                  class="c-order-list__main-list-item-background-image"
                  :image="{
                    value: {
                      src: order.items[0].image
                    }
                  }"
                />
                <div class="c-order-list__main-list-item-details">
                  <div class="c-order-list__main-list-item-details-title">
                    <div class="c-order-list__main-list-item-details-status">{{ order.status?.name ?? '' }}</div>
                  </div>
                  <div class="c-order-list__main-list-item-details-model">{{ order.series }}</div>
                  <div class="c-order-list__main-list-item-details-body">
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-create">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Ordered') }}:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">{{ $formatDate(order.createTime, 'MM-dd-yyyy') }}</div>
                    </div>
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-update">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Last update') }}:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">
                        {{ order.paymentTime ? $formatDate(order.paymentTime, 'MM-dd-yyyy') : $formatDate(order.createTime, 'MM-dd-yyyy') }}
                      </div>
                    </div>
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-id">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Order No') }}.:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">{{ order.orderId }}</div>
                    </div>
                  </div>
                </div>
                <div class="c-order-list__main-list-item-icon" v-if="order.jumpLink && $deviceComputes.largeThanTablet.value">
                  <Icon name="arrow-right" />
                </div>
              </div>
            </div>
          </div>
          <div class="c-order-list__empty" v-if="!appStore?.serviceOrderInfo?.length">
            <JssRichText class="c-order-list__empty-title" :field="tabList[tabCurrentIndex]?.fields?.emptyTitle" />
            <JssRichText class="c-order-list__empty-body" :field="tabList[tabCurrentIndex]?.fields?.emptyDescription" />
            <SiteButton class="c-order-list__empty-btn" v-bind="fields.emptyButton" />
          </div>
        </div>
        <!-- life -->
        <div class="c-order-list__shop" v-if="tabList?.length && tabList[tabCurrentIndex]?.fields?.tabCode?.value === 'shop'">
          <div class="c-order-list__main">
            <div class="c-order-list__main-order shop" v-if="shopMainOrder">
              <div class="c-order-list__main-order-swiper" ref="swiperEl">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in shopMainOrder.items" :key="index">
                    <img class="c-order-list__main-order-swiper-image" :src="item.image" :alt="item.name" />
                  </div>
                </div>
                <div class="swiper-pagination" ref="paginationEl" v-show="shopMainOrder.items?.length > 1" />
              </div>
              <div class="c-order-list__main-order-details">
                <div class="c-order-list__main-order-details-title">
                  <div class="c-order-list__main-order-details-status">{{ shopMainOrder.status?.name ?? '' }}</div>
                </div>
                <div class="c-order-list__main-order-details-model">{{ shopMainOrder.series }}</div>
                <div class="c-order-list__main-order-details-body shop">
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-create" v-if="shopMainOrder.createTime">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Ordered') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">{{ $formatDate(shopMainOrder.createTime, 'MM-dd-yyyy') }}</div>
                  </div>
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-update">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Last update') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">
                      {{ shopMainOrder.paymentTime ? $formatDate(shopMainOrder.paymentTime, 'MM-dd-yyyy') : $formatDate(shopMainOrder.createTime, 'MM-dd-yyyy') }}
                    </div>
                  </div>
                  <div class="c-order-list__main-order-details-body-item c-order-list__main-order-details-id" v-if="shopMainOrder.orderId">
                    <div class="c-order-list__main-order-details-body-item-label">{{ $t('Order No') }}:</div>
                    <div class="c-order-list__main-order-details-body-item-text">{{ shopMainOrder.orderId }}</div>
                  </div>
                </div>
                <div class="c-order-list__main-order-details-soldout" v-if="shopMainOrder.soldOut">
                  <JssRichText :field="fields.soldOutDescription" />
                </div>
                <div class="c-order-list__main-order-details-btn">
                  <SiteButton v-bind="fields.shopOrderDetailButton" @click="goDetails(shopMainOrder)" />
                </div>
              </div>
            </div>
            <div class="c-order-list__main-list" v-if="shopListOrders?.length">
              <div class="c-order-list__main-list-item shop" v-for="order in shopListOrders" :key="order.id">
                <div class="c-order-list__main-list-item-background-image shop" ref="listSwiperEl">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in order?.items" :key="index">
                      <img :src="item.image" :alt="item.name" />
                    </div>
                  </div>
                  <div class="swiper-pagination" ref="listPaginationEl" v-show="order?.items?.length > 1" />
                </div>
                <div class="c-order-list__main-list-item-details" @click="goDetails(order)">
                  <div class="c-order-list__main-list-item-details-title shop">
                    <div class="c-order-list__main-list-item-details-status">{{ order.status?.name ?? '' }}</div>
                  </div>
                  <div class="c-order-list__main-list-item-details-model">{{ order.series }}</div>
                  <div class="c-order-list__main-list-item-details-body">
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-create">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Ordered') }}:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">{{ $formatDate(order.createTime, 'MM-dd-yyyy') }}</div>
                    </div>
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-update">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Last update') }}:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">
                        {{ order.paymentTime ? $formatDate(order.paymentTime, 'MM-dd-yyyy') : $formatDate(order.createTime, 'MM-dd-yyyy') }}
                      </div>
                    </div>
                    <div class="c-order-list__main-list-item-details-body-item c-order-list__main-list-item-details-id">
                      <div class="c-order-list__main-list-item-details-body-item-label">{{ $t('Order No') }}.:</div>
                      <div class="c-order-list__main-list-item-details-body-item-text">{{ order.orderId }}</div>
                    </div>
                  </div>
                </div>
                <div class="c-order-list__main-list-item-icon" v-if="order.jumpLink && $deviceComputes.largeThanTablet.value" @click="goDetails(order)">
                  <Icon name="arrow-right" />
                </div>
              </div>
            </div>
          </div>
          <div class="c-order-list__empty" v-if="!appStore?.shopOrderInfo?.length">
            <JssRichText class="c-order-list__empty-title" :field="tabList[tabCurrentIndex]?.fields?.emptyTitle" />
            <JssRichText class="c-order-list__empty-body" :field="tabList[tabCurrentIndex]?.fields?.emptyDescription" />
            <SiteButton class="c-order-list__empty-btn" v-bind="fields.emptyButton" />
          </div>
        </div>
        <!-- 所有订单都为空时 -->
        <div
          class="c-order-list__title 11"
          v-if="
            (!appStore?.orderInfo?.length && !appStore?.shopOrderInfo?.length && !appStore?.partsOrderInfo?.length && !appStore?.serviceOrderInfo?.length && !tabList?.length) ||
              (appStore?.hideLifeOrder && appStore?.hideVehicleOrder && appStore?.hideServiceOrder && appStore?.hideWheelsOrder)
          "
        >
          <JssRichText :field="fields.emptyTitle" />
        </div>
        <div
          class="c-order-list__empty"
          v-if="
            (!appStore?.orderInfo?.length && !appStore?.shopOrderInfo?.length && !appStore?.partsOrderInfo?.length && !tabList?.length) ||
              (appStore?.hideLifeOrder && appStore?.hideVehicleOrder && appStore?.hideWheelsOrder)
          "
        >
          <JssRichText class="c-order-list__empty-title" :field="fields.emptySubTitle" />
          <JssRichText class="c-order-list__empty-body" :field="fields.emptyBody" />
          <SiteButton class="c-order-list__empty-btn" v-bind="fields.emptyButton" />
        </div>
        <div
          class="c-order-list__load"
          v-if="
            (fields.pageSize.value * pageNum < appStore.shopOrderInfo?.length && tabList[tabCurrentIndex]?.fields?.tabCode?.value === 'shop') ||
              (fields.pageSize.value * pageNum < appStore.partsOrderInfo?.length && tabList[tabCurrentIndex]?.fields?.tabCode?.value === 'parts')
          "
        >
          <SiteButton v-bind="fields.loadMore" @click="loadMore(tabList[tabCurrentIndex]?.fields?.tabCode?.value)" />
        </div>
      </div>
    </OverallSettings>
  </DashboardLayout>
</template>
<script>
import { reactive, toRefs, computed, nextTick, onMounted, inject, getCurrentInstance } from 'vue';
import { largeThanTablet } from '@/utils/dom-utils';
import { equalString } from '@/utils/string-utils';
import { getGlobalConfigs } from '@/utils/site-utils';
import { merge } from 'lodash';
import Swiper from 'swiper';
import useAppStore from '@/store/appStore';
import { arraySort } from '@/utils/array-utils';
import services from '@/services';
import { appendQuery, getBetterUrl } from '@/utils/uri-utils';
import api from '@/api';
export default {
  name: 'OrderList',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const toast = inject('toast');
    const loading = inject('loading');
    const appMethods = inject('appMethods');
    const { proxy } = getCurrentInstance();
    if (!props.fields) return;
    let swiper = null;
    const appStore = useAppStore();
    const state = reactive({
      detailHref: null,
      mhpHref: null,
      mainOrderType: null,
      tabCurrentIndex: 0,
      swiperEl: null,
      paginationEl: null,
      listSwiperEl: null,
      listPaginationEl: null,
      shopOrderDetailsLink: null,
      pageNum: 1
    });
    const computes = {
      mainOrder: computed(() => (appStore?.orderInfo?.length ? appStore.orderInfo[0] : null)),
      listOrders: computed(() => (appStore?.orderInfo?.length ? appStore.orderInfo.slice(1) : null)),
      tabList: computed(() => methods.getTabList(appStore?.hideLifeOrder, appStore?.hideVehicleOrder, appStore?.hideWheelsOrder, appStore?.hideServiceOrder)),
      shopMainOrder: computed(() => methods.getShopMainOrder(appStore?.shopOrderInfo)),
      shopListOrders: computed(() => methods.getShopListOrders(appStore?.shopOrderInfo)),
      partsMainOrder: computed(() => methods.getPartsMainOrder(appStore?.partsOrderInfo)),
      partsListOrders: computed(() => methods.getPartsListOrders(appStore?.partsOrderInfo)),
      serviceMainOrder: computed(() => methods.getServiceMainOrder(appStore?.serviceOrderInfo)),
      serviceListOrders: computed(() => methods.getServiceListOrders(appStore?.serviceOrderInfo)),
      mainOrderButton: computed(() => (computes.mainOrder.value.salesOrder || computes.mainOrder.value.carSeries.noConfigure ? props.fields.detailsButton?.fields : props.fields.convertButton?.fields))
    };
    const buildSwiper = () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
      swiper = new Swiper(state.swiperEl, {
        slidesPerView: 1,
        pagination: {
          el: state.paginationEl,
          clickable: true,
          bulletElement: 'div'
        }
      });
    };
    const buildListSwiper = () => {
      if (state.listSwiperEl && state.listSwiperEl.length) {
        state.listSwiperEl.forEach((el, index) => {
          let swiper_index = new Swiper(el, {
            slidesPerView: 1,
            pagination: {
              el: state.listPaginationEl[index],
              clickable: true,
              bulletElement: 'div'
            }
          });
        });
      }
    };
    const methods = {
      merge,
      largeThanTablet,
      changeTab(index) {
        state.tabCurrentIndex = index;
      },
      isBto(order) {
        return equalString(order.orderType, '200');
      },
      ifBto(order, fieldA, fieldB) {
        return methods.isBto(order) ? fieldA : fieldB;
      },
      loadMore(type) {
        state.pageNum++;
        if (type === 'shop') {
          methods.getShopListOrders(appStore?.shopOrderInfo);
        } else if (type === 'parts') {
          methods.getPartsListOrders(appStore?.partsOrderInfo);
        } else if (type === 'service') {
          methods.getServiceListOrders(appStore?.serviceOrderInfo);
        }
      },
      getOrderLink(order) {
        if (order.jumpLink) {
          return {
            link: { value: { href: order.jumpLink } }
          };
        } else {
          return false;
        }
      },
      goDetails: (order) => {
        if (order.jumpLink) {
          window.location = order.jumpLink;
        } else {
          return false;
        }
      },
      getShopMainOrder(shopOrderInfo) {
        nextTick(() => {
          buildSwiper();
          buildListSwiper();
        });
        if (shopOrderInfo?.length) {
          return shopOrderInfo[0];
        } else {
          return null;
        }
      },
      getPartsMainOrder(partsOrderInfo) {
        nextTick(() => {
          buildSwiper();
          buildListSwiper();
        });
        if (partsOrderInfo?.length) {
          return partsOrderInfo[0];
        } else {
          return null;
        }
      },
      getServiceMainOrder(serviceOrderInfo) {
        nextTick(() => {
          buildSwiper();
          buildListSwiper();
        });
        if (serviceOrderInfo?.length) {
          return serviceOrderInfo[0];
        } else {
          return null;
        }
      },
      getShopListOrders(shopOrderInfo) {
        let pageSize = props?.fields?.pageSize?.value;
        if (shopOrderInfo?.length > 1) {
          return shopOrderInfo.slice(1, pageSize * state.pageNum);
        } else {
          return null;
        }
      },
      getPartsListOrders(partsOrderInfo) {
        let pageSize = props?.fields?.pageSize?.value;
        if (partsOrderInfo?.length > 1) {
          return partsOrderInfo.slice(1, pageSize * state.pageNum);
        } else {
          return null;
        }
      },
      getServiceListOrders(serviceOrderInfo) {
        let pageSize = props?.fields?.pageSize?.value;
        if (serviceOrderInfo?.length > 1) {
          return serviceOrderInfo.slice(1, pageSize * state.pageNum);
        } else {
          return null;
        }
      },
      getTabList(hideLifeOrder, hideVehicleOrder, hideWheelsOrder, hideServiceOrder) {
        let tabList = [];
        props?.fields?.tabList?.forEach((item) => {
          if (item.fields.tabCode.value === 'shop' && !hideLifeOrder) {
            tabList.push(item);
          } else if (item.fields.tabCode.value === 'vehicle' && !hideVehicleOrder) {
            tabList.push(item);
          } else if (item.fields.tabCode.value === 'parts' && !hideWheelsOrder) {
            tabList.push(item);
          } else if (item.fields.tabCode.value === 'service' && !hideServiceOrder) {
            tabList.push(item);
          }
        });
        return tabList;
      }
    };
    onMounted(async () => {
      const { $jss } = proxy;
      const page = $jss.routeData();
      const [shopOrderDetailsLink] = getGlobalConfigs(page, 'shopOrderDetailsLink');
      state.shopOrderDetailsLink = shopOrderDetailsLink?.value?.href;
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      appStore
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-order-list {
  padding: 0 24px;
  &__tab {
    width: 100%;
    display: flex;
    position: relative;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    &__content {
      flex: 1;
      border-bottom: 1px solid #d0d0d0;
      display: flex;
      gap: 42px;
      height: 100%;
      padding-bottom: 12px;
    }
    &__item {
      font-size: 16px;
      line-height: 26px;
      color: #d0d0d0;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      &.active {
        color: $black;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: $black;
          position: absolute;
          bottom: -13px;
        }
      }
    }
  }
  &__title {
    font-size: 32px;
    line-height: 1;
    color: $black;
    margin-top: 48px;
    letter-spacing: 2px;
  }
  &__main {
    &-order {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: grid-width(1);
      margin-top: $space-24;
      overflow: hidden;
      &.shop {
        margin-top: 37px;
        gap: 40px;
      }
      &-swiper {
        width: 100%;
        overflow: hidden;
        position: relative;
        .swiper-slide {
          width: 100%;
          height: calc((100vw - 48px) * 1.345);
          display: flex;
          align-items: center;
          justify-content: center;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f2f1f0;
        }
        &-image {
          width: 100%;
          height: auto;
        }
        .swiper-pagination {
          position: absolute;
          width: 100%;
          display: flex;
          justify-content: center;
          bottom: 16px;
          z-index: 1;
          gap: 16px;
          .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: #b8b8b8;
            &-active {
              background: #2f2f2f;
            }
          }
        }
      }
      &-image {
        width: 100%;
        height: auto;
        margin: 60px auto;
        overflow: hidden;
        &.active {
          height: calc((100vw - 48px) / 3 * 2);
          margin: 0 auto;
          object-fit: cover;
        }
      }
      &-imagecon {
        width: 100%;
        height: calc((100vw - 48px) / 3 * 2);
        margin: 0 auto;
        overflow: hidden;
        background: #f2f1f0;
        &-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &-details {
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 2;
        &-title {
          display: flex;
          flex-direction: row;
          gap: $space-8;
          font-size: 12px;
          line-height: 14px;
          font-weight: 600;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
        &-status {
          color: #262626;
        }
        &-type {
          &.shop {
            color: #757575;
          }
        }
        &-info {
          color: #757575;
        }
        &-model {
          margin-top: $space-24;
          font-size: 26px;
          line-height: 26px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: $black;
        }
        &-body {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: $space-8 $space-16;
          margin-top: $space-24;
          flex-wrap: wrap;
          &.shop {
            margin-top: 30px;
          }
          &-item {
            display: flex;
            flex-direction: row;
            gap: $space-4;
            font-size: 12px;
            line-height: 20px;
            &-label {
              color: #757575;
            }
            &-text {
              color: #262626;
            }
          }
        }
        &-soldout {
          margin-top: $space-24;
        }
        &-btn {
          margin-top: $space-32;
          > .e-site-button {
            width: 100%;
          }
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $space-16;
        margin-top: $space-64;
        padding-top: $space-64;
        border-top: 1px solid #e3e3e3;
        overflow: hidden;
        &.shop {
          margin-top: 48px;
          padding-top: 48px;
        }
        &.clickable {
          cursor: pointer;
        }
        &-image {
          width: 100%;
          height: auto;
          overflow: hidden;
        }
        &-imagecon {
          width: 100%;
          height: calc((100vw - 48px) / 3 * 2);
          margin: 0 auto;
          overflow: hidden;
          background: #f2f1f0;
          &-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        &-background-image {
          width: 100%;
          height: calc((100vw - 48px) * 0.56);
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          &.shop {
            height: calc((100vw - 48px) * 1.345);
            overflow: hidden;
          }
          .swiper-wrapper {
            .swiper-slide {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #f2f1f0;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
          .swiper-pagination {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
            bottom: 16px;
            z-index: 1;
            gap: 16px;
            .swiper-pagination-bullet {
              width: 6px;
              height: 6px;
              border-radius: 100%;
              background: #b8b8b8;
              &-active {
                background: #2f2f2f;
              }
            }
          }
        }
        &-details {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: $space-16;
          &-title {
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            display: flex;
            gap: 8px;
            &.shop {
              font-size: 14px;
              line-height: 20px;
            }
          }
          &-status {
            color: #262626;
            font-weight: 700;
          }
          &-info {
            color: #757575;
          }
          &-model {
            font-size: 26px;
            line-height: 32px;
            color: $black;
          }
          &-body {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: $space-8 $space-16;
            &-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: $space-4;
              font-size: 12px;
              line-height: 20px;
              &-label {
                color: #757575;
              }
              &-text {
                color: #262626;
              }
            }
          }
        }
        &-icon {
          width: $space-48;
          height: $space-48;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
  &__empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 48px;
    &-title {
      font-size: 18px;
      line-height: 28px;
      color: $black;
      font-weight: 700;
    }
    &-body {
      font-size: 16px;
      line-height: 24px;
      color: #757575;
      margin-top: 8px;
      margin-bottom: 48px;
    }
  }
  &__load {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }
  @include tablet-landscape {
    padding: 0 108px;
    &__tab {
      &__content {
        gap: 62px;
        padding-bottom: 17px;
      }
      &__item {
        line-height: 24px;
        &.active {
          &:after {
            bottom: -18px;
          }
        }
      }
    }
    &__title {
      font-size: 56px;
      margin-top: 80px;
      letter-spacing: 3px;
    }
    &__main {
      &-order {
        min-height: 400px;
        flex-direction: row;
        gap: grid-width(1);
        margin-top: $space-40;
        &.active {
          gap: 24px;
        }
        &.shop {
          margin-top: 80px;
          gap: 105px;
        }
        &-swiper {
          width: grid-width(6.5);
          .swiper-slide {
            height: calc(grid-width(6.5) * 1.24);
          }
          .swiper-pagination {
            bottom: 26px;
          }
        }
        &-image {
          width: grid-width(10);
          margin: 0;
          &.active {
            height: calc(grid-width(10) * 2 / 3);
            margin: 0;
          }
        }
        &-imagecon {
          width: grid-width(10);
          height: calc(grid-width(10) * 2 / 3);
          margin: 0;
        }
        &-details {
          width: auto;
          &-title {
            gap: $space-16;
            font-size: 14px;
            line-height: 20px;
          }
          &-model {
            font-size: 32px;
            line-height: 32px;
          }
          &-body {
            gap: $space-24;
            margin-top: $space-16;
          }
          &-soldout {
            margin-top: $space-16;
          }
          &-btn {
            margin-top: $space-48;
            > .e-site-button {
              width: auto;
            }
          }
        }
      }
      &-list {
        &-item {
          flex-direction: row;
          gap: $space-24;
          &.shop {
            margin-top: 64px;
            padding-top: 64px;
          }
          &-image {
            width: grid-width(3);
          }
          &-imagecon {
            width: grid-width(3);
            height: grid-width(calc(3 * 0.56));
          }
          &-background-image {
            width: grid-width(3);
            height: grid-width(calc(3 * 0.56));
            &.shop {
              height: calc(grid-width(3) * 1.22);
            }
          }
          &-details {
            flex: 1;
            gap: $space-8;
            cursor: pointer;
            &-title {
              font-weight: 700;
              font-size: 14px;
              line-height: 24px;
              gap: 16px;
            }
            &-body {
              gap: $space-24;
            }
          }
        }
      }
    }
    &__empty {
      &-body {
        width: grid-width(8);
        margin-top: 24px;
      }
    }
    &__load {
      margin-top: 120px;
    }
  }
}
</style>
